import React from 'react';
import { Container, Paper, Box, Typography, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import SingleOffer from '../../../employer/partials/SingleOffer'
import { useNewAppUrl } from 'fb/auth'

const Empresa = ({ employer }) => {
  const token = useNewAppUrl(`/admin/offer/${employer?.id}/edit`);

  if (!employer) return null

  return (
    <Container maxWidth="sm">
      <Paper square>
        <Box p={2} style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Typography component="h5" variant="h5">Datos de la oferta</Typography>
          <IconButton
            key="edit"
            aria-label="Edit"
            color="primary"
            onClick={() => window.location.replace(token)}
          >
            <EditIcon />
          </IconButton>
        </Box>

        <SingleOffer opp={employer} />
      </Paper>
    </Container>
  )
}

export default Empresa