
import React from 'react'
import { Container, Box } from '@material-ui/core'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  if (props.noPadding) return (
    <>
      {value === index && <Box py={2}>{children}</Box>}
    </>
  )

  else return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={2}>{children}</Box>}
    </Container>
  )
}

export default TabPanel