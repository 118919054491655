import React from 'react'
import { Paper, Box } from '@material-ui/core'
import { Edit, Close } from '@material-ui/icons'
import {Editor} from 'components/UI/common'
import ProfileDetail from './ProfileDetail'
import EditProfile from './EditProfile'

export default function Profile() {
  const [toggleEdit, setToggleEdit] = React.useState(false)

  return (
    <>
      <Editor onClick={() => setToggleEdit(!toggleEdit)} size="small" aria-label="edit" color="primary">
        {!toggleEdit ? <Edit /> : <Close />}
      </Editor>

      <Paper style={{ height: '100%' }}>
        <Box p={2}>
          {
            toggleEdit ? <EditProfile close={() => setToggleEdit(false)} /> : <ProfileDetail setToggleEdit={() => setToggleEdit(true)} />
          }
        </Box>
      </Paper>
    </>
  )
}
