import React, { useState } from 'react';
import { db } from 'fb';
import { DialogContent, DialogActions, FormControl, FormHelperText, Select, MenuItem, FormLabel, Button } from '@material-ui/core'

const Insertar = ({update, employer, adalabers, hideModal}) => {
  const [adalaber, setAdalaber] = useState(null);
  const [adalaberName, setAdalaberName] = useState('Elige Adalaber');

  const saveInserted = (whichWay) => async (event) => {
    event.preventDefault();
    const so = whichWay === true
      ? await db.acceptAdalaber(adalaber.id, employer.id)
      : await db.unAcceptAdalaber(adalaber.id, employer.id);
    console.log(so)
    hideModal()
  }

  const handleChange = (e) => {
    const name = e.target.value
    setAdalaberName(name)
    const adalaber = adalabers.find(ada => ada.fullName === name)
    setAdalaber(adalaber)
  }

  return <>
      <DialogContent>
        <FormControl component="fieldset" style={{ marginTop: '2em', marginBottom: '1em', width: '100%' }}>
          <FormLabel component="legend" style={{ marginBottom: '1em' }} htmlFor="adalaber">
            Adalaber
          </FormLabel>

          <Select
            value={adalaberName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            id="adalaber">
              {
                adalabers && adalabers.map(ada => <MenuItem value={ada.fullName} key={ada.id}>{ada.fullName}</MenuItem>)
              }
          </Select>

          <FormHelperText>Elige adalaber</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal} color="secondary">
          Cancelar
        </Button>
        <Button variant="outlined" onClick={saveInserted(false)} color="primary">
          Eliminar
        </Button>
        <Button variant="outlined" onClick={saveInserted(true)} color="primary">
          Insertar
        </Button>
      </DialogActions>
    </>;
}

export default Insertar