import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { SignUpImg } from '../common'
import NotFound from './undraw_not_found.svg'

export default function NothingToSeeHere({text}) {
  return (
    <Box p={4} style={{ maxWidth: '65ch' }}>
      <SignUpImg large src={NotFound} alt="No hay ofertas todavía"/>
      <Typography variant="body2" gutterBottom>
        {text}
      </Typography>
    </Box>
  )
}
