import React from 'react'
import { useSession } from 'fb/auth'
import { getURLLink } from '../../config/utils'
// import firebase from '../../firebase/firebase'
// import { useDocumentData } from 'react-firebase-hooks/firestore'
import {
  CardContent,
  Fab,
  Avatar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Tooltip
} from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { IconFlagES, IconFlagUK } from 'material-ui-flags'
import SmilingGirl from 'components/UI/CustomIcons/SmilingGirl'
// import Skills from 'components/UI/CustomIcons/Skills'
import { AdaSectionHeader } from 'components/UI/common'
import Markdown from 'components/UI/Markdown'

import Studies from 'components/UI/CustomIcons/Studies'
import Library from 'components/UI/CustomIcons/Library'
import English from 'components/UI/CustomIcons/English'
import Translation from 'components/UI/CustomIcons/Translation'
import Coding from 'components/UI/CustomIcons/Coding'
import Location from 'components/UI/CustomIcons/Location'
import Zeppelin from 'components/UI/CustomIcons/Zeppelin'
import Goal from 'components/UI/CustomIcons/Goal'
import Bullseye from 'components/UI/CustomIcons/Bullseye'
import Tools from 'components/UI/CustomIcons/Tools'
import Exp from 'components/UI/CustomIcons/Exp'
import Earth from 'components/UI/CustomIcons/Earth'
import Github from 'components/UI/CustomIcons/Github'
import LinkedIn from 'components/UI/CustomIcons/LinkedIn'
import Portfolio from 'components/UI/CustomIcons/Portfolio'
import Email from 'components/UI/CustomIcons/Email'
import Tel from 'components/UI/CustomIcons/Tel'

const AdalaberDetail = props => {
  const user = useSession()
  const { adalaber, OfferID } = props

  const profileKeys = {
    'Nivel de estudios': {
      icon: <Library />,
      prop: 'studies'
    },
    'Tipo de estudios': {
      icon: <Studies />,
      prop: 'studiesDescription'
    },
    'Nivel de inglés': {
      icon: <English />,
      prop: 'englishLevel'
    },
    'Otros idiomas ': {
      icon: <Translation />,
      prop: 'otrosIdiomas'
    },
    'Ubicaciones adicionales para trabajar': {
      icon: <Location />,
      prop: 'additionalLocations'
    },
    'Disponibilidad para viajar': {
      icon: <Zeppelin />,
      prop: 'movilidad',
      value: adalaber.movilidad ? adalaber.movilidad[0] : ''
    },
    'Proyección laboral': {
      icon: <Goal />,
      prop: 'careerProjection',
      value: Array.isArray(adalaber.careerProjection)
        ? adalaber.careerProjection.map(exp => <div>. {exp}</div>)
        : adalaber.careerProjection
    },
    '¿Qué tipo de empresas le interesan?': {
      icon: <Bullseye />,
      prop: 'preferredCompanies',
      value:
        adalaber.preferredCompanies &&
        Array.isArray(adalaber.preferredCompanies)
          ? adalaber.preferredCompanies.map(exp => <div>· {exp}</div>)
          : adalaber.preferredCompanies
    },
    'Softskills en las que destaca la candidata': {
      icon: <Tools />,
      prop: 'softskills',
      value:
        adalaber.softskills &&
        adalaber.softskills.map(exp => <div>· {exp}</div>)
    },
    'Experiencia previa': {
      icon: <Exp />,
      prop: 'experiencia',
      value:
        adalaber.experiencia &&
        adalaber.experiencia.map(exp => <div>· {exp}</div>)
    },
    'Otros conocimientos interesantes': {
      icon: <Earth />,
      prop: 'comments'
    },
    Github: {
      icon: <Github />,
      prop: 'github',
      url: true
    },
    LinkedIn: {
      icon: <LinkedIn />,
      prop: 'linkedin',
      url: true
    },
    Portafolio: {
      icon: <Portfolio />,
      prop: 'portafolio',
      url: true
    },
    Mail: {
      icon: <Email />,
      prop: 'email',
      url: true
    },
    Teléfono: {
      icon: <Tel />,
      prop: 'tel'
    }
  }

  if (!adalaber || !user) return null

  return (
    <Box>
      <AdaSectionHeader forwardedAs="DialogTitle" light>
        <Box
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            style={{ width: 90, height: 90, marginBottom: 16 }}
            src={adalaber.avatar}
          >
            {adalaber.avatar || <SmilingGirl />}
          </Avatar>
          <Typography component="h1" variant="h3" style={{ fontSize: '2em' }}>
            {adalaber.fullName}
          </Typography>
          <Typography component="h1" variant="h4" style={{ fontSize: '1.4em' }}>
            {adalaber.email}
          </Typography>
        </Box>
      </AdaSectionHeader>

      <Box p={4} pb={0}>
        {adalaber.shortBio && (
          <Box mb={2}>
            <Typography style={{ fontWeight: 'normal' }} gutterBottom>
              Relato profesional
            </Typography>
            <Typography>
              <Markdown>{adalaber.shortBio}</Markdown>
            </Typography>
          </Box>
        )}

        {adalaber.messageForEmployer &&
          Object.keys(adalaber.messageForEmployer).includes(OfferID) && (
            <Box mb={2}>
              <Typography style={{ fontWeight: 'normal' }} gutterBottom>
                Mensaje para{' '}
                {user && user.profile && user.profile.name
                  ? `${user.profile.name}`
                  : 'la empresa'}
              </Typography>
              <Typography>
                <Markdown>{adalaber.messageForEmployer[OfferID]}</Markdown>
              </Typography>
            </Box>
          )}
      </Box>

      <CardContent>
        <List>
          {Object.entries(profileKeys).map(
            ([key, { prop, icon, value, url }]) => {
              return (
                <>
                  {adalaber[prop] && (
                    <ListItem
                      {...url && {
                        component: 'a',
                        target: '_blank',
                        href: getURLLink(adalaber[prop]),
                        rel: 'noopener noreferrer'
                      }}
                      key={key}
                    >
                      <ListItemAvatar>
                        <Avatar style={{ background: 'whitesmoke' }}>
                          {icon}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        multiline
                        primary={key}
                        secondary={value || adalaber[prop]}
                      />
                    </ListItem>
                  )}
                </>
              )
            }
          )}
        </List>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          {adalaber.cvURL && (
            <Fab
              style={{ color: 'white' }}
              component="a"
              href={adalaber.cvURL}
              target="_blank"
              rel="noopener"
              variant="extended"
              color="primary"
              aria-label="download CV"
            >
              <IconFlagES style={{ marginRight: '0.25rem' }} />
              Descargar CV
            </Fab>
          )}
          {adalaber.cvEnURL && (
            <Fab
              style={{ color: 'white' }}
              component="a"
              href={adalaber.cvEnURL}
              target="_blank"
              rel="noopener"
              variant="extended"
              color="primary"
              aria-label="download CV"
            >
              <IconFlagUK style={{ marginRight: '0.25rem' }} />
              Descargar CV
            </Fab>
          )}
        </Box>
      </CardContent>
    </Box>
  )
}

export default AdalaberDetail

/*
  <Container maxWidth="xs" style={{ margin: '1em auto 2em' }}>
  {error && <strong>Error: {JSON.stringify(error)}</strong>}
  {loading && <span>Document: Loading...</span>}
  {adalaber && <span>Document: {JSON.stringify(adalaber)}</span>}
  </Container>
*/
