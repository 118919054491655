import React from 'react';
import { SvgIcon } from '@material-ui/core'


const LinkedIn = () => (
  <SvgIcon>
    <svg viewBox="0 -11 512 512" xmlns="http://www.w3.org/2000/svg"><g fill="#069"><path d="m6.488281 159.683594h109.753907v330.207031h-109.753907zm0 0"/><path d="m62.089844.5c-37.542969 0-62.089844 24.652344-62.089844 57.054688 0 31.6875 23.816406 57.042968 60.648438 57.042968h.714843c38.277344 0 62.097657-25.355468 62.097657-57.042968-.714844-32.402344-23.820313-57.054688-61.371094-57.054688zm0 0"/><path d="m385.644531 151.933594c-58.234375 0-84.328125 32.003906-98.914062 54.507812v-46.757812h-109.757813v330.207031h109.753906v-184.402344c0-9.871093.714844-19.726562 3.613282-26.785156 7.933594-19.714844 25.992187-40.136719 56.3125-40.136719 39.714844 0 55.601562 30.28125 55.601562 74.671875v176.652344h109.746094v-189.335937c0-101.425782-54.148438-148.621094-126.355469-148.621094zm0 0"/></g></svg>
  </SvgIcon>
)

export default LinkedIn