import React from 'react'
import { useFirestore } from 'reactfire'
import { useAuth } from 'fb/auth'
import { useSnackbar } from 'notistack'

import { Box } from '@material-ui/core'
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const workingModes = {
  REMOTE: '100% remoto',
  ON_SITE: '100% presencial',
  SEMI_PRESENTIAL: 'Semipresencial',
  TEMPORARY_REMOTE: 'Remoto situación sanitaria',
  OTHER: 'Otro'
}

const SingleOfferForm = ({ opp: opportunity, close }) => {
  const user = useAuth()
  const [opp, setOpp] = React.useState(() => ({
    ...opportunity,
    incorporationDate: new Date(opportunity.incorporationDate)
  }))
  const { enqueueSnackbar } = useSnackbar()
  const firestore = useFirestore()

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      // setLoading(true);
      await firestore
        .collection('employerUsers')
        .doc(user.uid)
        .collection('opportunities')
        .doc(opp.id)
        .set({ ...opp, incorporationDate: opp.incorporationDate.toISOString() })
    } catch (err) {
      console.log(err)
    }
    // setLoading(false);
    if (close) close()
    enqueueSnackbar('Guardada oferta', { variant: 'success' })
  }

  const handleChange = prop => event => {
    setOpp({ ...opp, [prop]: event.target.value })
  }

  const handleDate = date => setOpp({ ...opp, incorporationDate: date })

  if (!opp) return null
  return <form onSubmit={handleSubmit}>
      <TextField onChange={handleChange('title')} variant="outlined" margin="normal" fullWidth id="title" label="0. Título de la oferta" helperText="Introduce un título descriptivo." name="title" value={opp.title} style={{ marginBottom: '2em' }} />
      <br />

      <FormControl component="fieldset" style={{ marginTop: '2em' }}>
        <FormLabel component="legend" style={{ marginBottom: '1em' }}>
          1. Indica por favor el nivel mínimo de inglés que las candidatas deben tener
        </FormLabel>
        <RadioGroup aria-label="englishLevel" name="englishLevel" value={opp.englishLevel} onChange={handleChange('englishLevel')}>
          <FormControlLabel value="No es necesario" control={<Radio />} label="No es necesario" />
          <FormControlLabel value="B1" control={<Radio />} label="B1" />
          <FormControlLabel value="B2" control={<Radio />} label="B2" />
          <FormControlLabel value="C1" control={<Radio />} label="C1" />
          <FormControlLabel value="C2" control={<Radio />} label="C2" />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset" style={{ marginTop: '2em' }}>
        <FormLabel component="legend">
          2. Indica el tipo de contrato
        </FormLabel>
        <FormHelperText style={{ marginBottom: '1em' }}>
          No trabajamos con becas, prácticas ni contratos formativos
        </FormHelperText>
        <RadioGroup aria-label="contractType" name="contractType" value={opp.contractType} onChange={handleChange('contractType')}>
          <FormControlLabel value="Contrato indefinido" control={<Radio />} label="Contrato indefinido" />
          <FormControlLabel value="Contrato temporal (mínimo 6 meses)" control={<Radio />} label="Contrato temporal (mínimo 6 meses)" />
          <FormControlLabel value="Contrato de servicios (autónoma)" control={<Radio />} label="Contrato de servicios (autónoma)" />
          <FormControlLabel value="Otro" control={<Radio />} label="Otro" />
        </RadioGroup>
      </FormControl>

      <TextField style={{ display: opp.contractType !== 'Otro' ? 'none' : 'block' }} onChange={handleChange('otherContractType')} variant="outlined" margin="normal" fullWidth id="otherContractType" label="Otro tipo de contrato" name="otherContractType" value={opp.otherContractType} />

      <FormControl component="fieldset" style={{ marginTop: '2em' }}>
        <FormLabel component="legend" style={{ marginBottom: '1em' }}>
          3. Indica la escala salarial que ofrece la empresa
        </FormLabel>
        <RadioGroup helperText="Por favor indicar el salario bruto anual. Recuerda que en Adalab no trabajamos con ofertas con un rango salarial a los 16.000 €" aria-label="salaryRange" name="salaryRange" value={opp.salaryRange} onChange={handleChange('salaryRange')}>
          <FormControlLabel value="16.000 - 18.000 €" control={<Radio />} label="16.000 - 18.000 €" />
          <FormControlLabel value="18.000 - 20.000 €" control={<Radio />} label="18.000 - 20.000 €" />
          <FormControlLabel value="20.000 - 22.000€" control={<Radio />} label="20.000 - 22.000€" />
          <FormControlLabel value="22.000 - 24.000 €" control={<Radio />} label="22.000 - 24.000 €" />
          <FormControlLabel value="más de 24.000 €" control={<Radio />} label="más de 24.000 €" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
        <FormLabel component="legend">
          4. Indica la modalidad del puesto de trabajo:
        </FormLabel>
        <RadioGroup aria-label="workingMode" name="workingMode" value={opp.workingMode} onChange={handleChange('workingMode')}>
          {Object.keys(workingModes).map(mode => (
            <FormControlLabel
              key={mode}
              value={mode}
              control={<Radio required={true} />}
              label={workingModes[mode]}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {['ON_SITE', 'SEMI_PRESENTIAL'].includes(opp.workingMode) && <TextField required onChange={handleChange('opportunityLocation')} variant="outlined" margin="normal" fullWidth id="opportunityLocation" label="Ubicación de puesto de trabajo" helperText="Por favor indicad la localidad del puesto de trabajo" name="opportunityLocation" value={opp.opportunityLocation} />}

      {opp.workingMode == 'OTHER' && <TextField required onChange={handleChange('workingModeOther')} variant="outlined" margin="normal" fullWidth id="workingModeOther" label="Describe la modalidad y ubicación del puesto de trabajo" name="workingModeOther" value={opp.workingModeOther} />}

      <FormControl component="fieldset" style={{ marginTop: '2em' }}>
        <FormLabel component="legend" style={{ marginBottom: '1em' }}>
          5. ¿La empresa ofrece la posibilidad de un trabajo con jornada reducida?
        </FormLabel>
        <FormHelperText>
          (30 - 35 horas) y/o la posibilidad de acordar un horario intensivo
          (por ejemplo 7:00-15:00)
        </FormHelperText>
        <RadioGroup value={opp.reducedSchedule} onChange={handleChange('reducedSchedule')}>
          <FormControlLabel value="Sí" control={<Radio />} label="Sí" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset" style={{ marginTop: '2em' }}>
        <FormLabel component="legend" style={{ marginBottom: '1em' }}>
          6. ¿Es requisito indispensable que las candidatas posean un título universitario?
        </FormLabel>
        <RadioGroup value={opp.universitaryStudies} onChange={handleChange('universitaryStudies')}>
          <FormControlLabel value="Sí" control={<Radio />} label="Sí" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset" style={{ marginTop: '2em', marginBottom: '1em' }}>
        <FormLabel component="legend" style={{ marginBottom: '1em' }} htmlFor="incorporationDate">
          7. Fecha de incorporación aproximada
        </FormLabel>
        <DatePicker dateFormat="dd/MM/yyyy" selected={opp.incorporationDate} onChange={date => handleDate(date)} id="incorporationDate" />
      </FormControl>

      {/* <TextField
        onChange={handleChange('opportunities')}
        variant="outlined"
        margin="normal"
        fullWidth
        id="opportunities"
        label="Número de candidaturas"
        helperText="Indica el número de candidatas de Adalab que la empresa está interesada en contratar"
        name="opportunities"
        value={opp.opportunities}
        type="Number"
      /> */}

      <TextField multiline onChange={handleChange('positionSummary')} variant="outlined" margin="normal" fullWidth id="positionSummary" label="10. Resumen del puesto de trabajo" helperText="Contar por favor de forma clara y resumida qué hará una adalaber si finalmente es contratada en la empresa: tipo de proyectos, tecnologías que utilizará, cómo será su onboarding, etc." name="positionSummary" value={opp.positionSummary} />

      <TextField multiline onChange={handleChange('motivational')} variant="outlined" margin="normal" fullWidth id="motivational" label="11. ¿Por qué aplicar a esta oferta?" helperText="Incluir qué ofrece la empresa para un perfil junior en términos de formación, flexibilidad, ambiente, proyección, apoyo..." name="motivational" value={opp.motivational} />

      <TextField required onChange={handleChange('apoyoEnLaEmpresa')} variant="outlined" margin="normal" multiline fullWidth id="apoyoEnLaEmpresa" label="12. ¿Qué apoyo va a tener la adalaber en la empresa?" helperText="Como perfil junior que es, incluir aquí si la alumna contará con un tutor/a en la empresa, con formación específica durante su onboarding y/o si la empresa cuenta con al menos un perfil senior de front-end al que la alumna pueda consultar" name="apoyoEnLaEmpresa" value={opp.apoyoEnLaEmpresa} />

      <TextField onChange={handleChange('contactEmail')} variant="outlined" margin="normal" fullWidth id="contactEmail" label="13. Mail de contacto" helperText="Indicar por favor el mail de contacto si tenemos alguna duda desde Adalab una vez leido el formulario" name="contactEmail" value={opp.contactEmail} type="email" />

      <TextField multiline onChange={handleChange('comments')} variant="outlined" margin="normal" fullWidth id="comments" label="14. Comentarios" helperText="Incluir aquí cualquier comentario adicional que quieras que las alumnas interesadas en aplicar a la oferta puedan leer" name="comments" value={opp.comments} style={{ marginBotton: '1em' }} />

      <Box mt={2}>
        <Button type="submit" fullWidth variant="contained" color="primary" size="large">
          Guardar oferta
        </Button>
      </Box>
    </form>
}

export default SingleOfferForm
