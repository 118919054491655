import React from 'react'
import omit from 'lodash.omit'
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom'
// import { useFirestore, useFirestoreCollectionData } from 'reactfire'

import { useSession } from 'fb/auth'
import { auth } from 'fb'

import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'

import {
  Container,
  Box,
  Divider,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import LoadingButton from '../../components/UI/LoadingButton'

import { Visibility, VisibilityOff } from '@material-ui/icons'

import FormHeader from 'components/UI/FormHeader'

const SignUpForm = props => {
  const user = useSession()

  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [logoChecked, setLogoChecked] = React.useState(false)

  const [credentials, setCredentials] = React.useState({
    cif: '',
    name: '',
    email: '',
    location: '',
    website: '',
    linkedin: '',
    logo: '',
    description: '',
    type: '',
    numberOfEmployees: '',
    passwordOne: '',
    showPassword: true,
    rgpdConsent: false
  })

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setLoading(true)

      // HERE
      const result = await auth.doCreateEmployerWithEmailAndPassword(
        credentials.email,
        credentials.passwordOne,
        {
          ...omit(credentials, ['passwordOne', 'showPassword'])
        }
      )

      const prod =
        !window.location.href.includes('netlify') &&
        !window.location.href.includes('localhost') &&
        !window.location.href.includes('emma-demo')

      if (prod && logoChecked) {
        fetch('https://hooks.zapier.com/hooks/catch/2965889/b38fdqy/', {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            logoUrl: credentials.logo,
            companyName: credentials.name
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data)
          })
          .catch(error => {
            console.error('Error:', error)
          })
      }

      enqueueSnackbar(result.message, { variant: result.variant })
    } catch (err) {
      enqueueSnackbar(err.message, { variant: err.variant })
    }
    setLoading(false)
  }

  const handleChange = prop => event => {
    setCredentials({ ...credentials, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setCredentials({
      ...credentials,
      showPassword: !credentials.showPassword
    })
  }

  const handleRGPD = () => {
    setCredentials({
      ...credentials,
      rgpdConsent: !credentials.rgpdConsent
    })
  }

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader()
    // const name = target.accept.includes('image') ? 'images' : 'videos';

    fileReader.readAsDataURL(target.files[0])

    fileReader.onload = e => {
      const size = target.files[0].size
      if (size > 1048486) {
        enqueueSnackbar('Tu logo es demasiado grande. El máximo es 1mb', {
          variant: 'error'
        })
        return
      }
      setCredentials({
        ...credentials,
        logo: e.target.result
      })
    }
  }

  if (user) return <Redirect to="/" />

  return (
    <Container
      disableGutters
      component="main"
      maxWidth="xs"
      style={{ padding: '2em 0' }}
    >
      <FixedLinearProgress isLoading={loading} />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="stretch"
        height="100%"
      >
        <FormHeader icon="lock" title="Crear cuenta de Empresa">
          <Link
            component={RouterLink}
            to="/signup"
            variant="body2"
            align="center"
          >
            ¿Quieres crear una cuenta de adalaber? Haz click aquí!
          </Link>
          <br />
        </FormHeader>

        {/* <Typography variant="body1">Por favor crea tu usuario de empresa incluyendo un mail y contraseña</Typography>
        <Typography variant="body1">Si ya estaba registrada, puedes editar los datos del perfil de empresa</Typography>
        <Typography variant="body1">Si es la primera vez que te registras en nuestra plataforma, completa el perfil completo de empresa</Typography> */}

        <form onSubmit={handleSubmit}>
          <>
            <Divider />
            <Box mt={2} mb={2}>
              <Typography align="center" variant="body2" component="h3">
                Por favor, crea tu nuevo usuario de empresa utilizando <br /> un
                mail de registro y contraseña
              </Typography>
            </Box>

            <TextField
              onChange={handleChange('cif')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="cif"
              label="CIF de la empresa"
              name="cif"
              autoFocus
            />

            <TextField
              onChange={handleChange('name')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nombre de la empresa"
              helperText="Si el nombre legal es diferente del comercial, por favor incluir ambos"
              name="name"
              autoComplete="name"
              value={credentials.name}
            />

            <TextField
              onChange={handleChange('email')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              helperText="Este email será con el que la cuenta de empresa quedará registrada en la plataforma de empleo de Adalab, pero no se utilizará para comunicaciones."
              value={credentials.email}
            />

            <TextField
              onChange={handleChange('passwordOne')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="passwordOne"
              label="Contraseña"
              value={credentials.passwordOne}
              type={credentials.showPassword ? 'text' : 'password'}
              id="password"
              style={{ marginBottom: '2em' }}
              InputProps={{
                minLength: 6,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Enseñar o esconder contraseña"
                      onClick={handleClickShowPassword}
                      onMouseDown={e => e.preventDefault()}
                    >
                      {credentials.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <br />

            <Divider />

            <br />

            <TextField
              onChange={handleChange('location')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="location"
              label="Ubicación"
              helperText="Incluir localidad y provincia donde está situada la empresa"
              name="location"
              value={credentials.location}
            />

            <TextField
              onChange={handleChange('website')}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="website"
              label="Página web"
              type="website"
              name="website"
              value={credentials.website}
            />

            <TextField
              onChange={handleChange('linkedin')}
              variant="outlined"
              margin="normal"
              fullWidth
              id="linkedin"
              label="Página de Linkedin"
              name="linkedin"
              value={credentials.linkedin}
            />

            <br />
            <br />
            <Divider />

            <Box mt={2} mb={2} pt={2} pb={2}>
              <FormLabel component="legend">Logo de la empresa</FormLabel>
              <FormLabel
                component="small"
                style={{ fontSize: '80%', marginBottom: '1em' }}
              >
                Máximo 1mb
              </FormLabel>
              <br />

              {credentials.logo.length > 2 && (
                <img src={credentials.logo} alt="" />
              )}

              <br />
              <input
                accept="image/*"
                id="button-file"
                multiple={false}
                type="file"
                style={{ display: 'none' }}
                onChange={handleCapture}
              />
              <label htmlFor="button-file">
                <Button variant="outlined" color="primary" component="span">
                  {credentials.logo ? 'Cambiar logo' : 'Subir logo'}
                </Button>
              </label>
            </Box>

            <Box my={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={logoChecked}
                    value={logoChecked}
                    onChange={() => setLogoChecked(prev => !prev)}
                  />
                }
                label={
                  'Autorizo a Adalab a utilizar el logo para ser publicado en su web en la sección de empresas colaboradoras, en caso de contratar a una o varias alumnas de Adalab.'
                }
              />
            </Box>

            <br />

            <Divider />

            <br />

            <TextField
              multiline
              onChange={handleChange('description')}
              required
              variant="outlined"
              margin="normal"
              fullWidth
              id="description"
              label="Breve descripción de la empresa"
              helperText="Contar de forma resumida a qué se dedica la empresa"
              name="description"
              value={credentials.description}
            />

            <FormControl
              required
              component="fieldset"
              style={{ marginTop: '2em' }}
            >
              <FormLabel component="legend" style={{ marginBottom: '1em' }}>
                Tipología de la empresa
              </FormLabel>
              <RadioGroup
                aria-label="tipologia"
                name="tipologia"
                value={credentials.type}
                onChange={handleChange('type')}
              >
                <FormControlLabel
                  value="Empresa de servicios de desarrollo para terceros (boutiques de desarrollo, consultoras, estudios de diseño web)"
                  control={<Radio />}
                  label="Empresa de servicios de desarrollo para terceros (boutiques de desarrollo, consultoras, estudios de diseño web)"
                />
                <FormControlLabel
                  value="Empresa de producto (empresas cuyo producto es digital: aplicación, plataforma, ecommerce)"
                  control={<Radio />}
                  label="Empresa de producto (empresas cuyo producto es digital: aplicación, plataforma, ecommerce)"
                />
                <FormControlLabel
                  value="Agencia de comunicación y/o marketing y/o publicidad"
                  control={<Radio />}
                  label="Agencia de comunicación y/o marketing y/o publicidad"
                />
              </RadioGroup>
            </FormControl>

            <FormControl
              required
              component="fieldset"
              style={{ marginTop: '2em' }}
            >
              <FormLabel component="legend" style={{ marginBottom: '1em' }}>
                Número de empleados
              </FormLabel>
              <RadioGroup
                aria-label="numberOfEmployees"
                name="numberOfEmployees"
                value={credentials.numberOfEmployees}
                onChange={handleChange('numberOfEmployees')}
              >
                <FormControlLabel
                  value="2-10"
                  control={<Radio />}
                  label="2-10"
                />
                <FormControlLabel
                  value="11-50"
                  control={<Radio />}
                  label="11-50"
                />
                <FormControlLabel
                  value="51-200"
                  control={<Radio />}
                  label="51-200"
                />
                <FormControlLabel
                  value="201-500"
                  control={<Radio />}
                  label="201-500"
                />
                <FormControlLabel
                  value="501-1.000"
                  control={<Radio />}
                  label="501-1.000"
                />
                <FormControlLabel
                  value="1.001-5.000"
                  control={<Radio />}
                  label="1.001-5.000"
                />
                <FormControlLabel
                  value="5.001-10.000"
                  control={<Radio />}
                  label="5.001-10.000"
                />
                <FormControlLabel
                  value="Más de 10.000"
                  control={<Radio />}
                  label="Más de 10.000"
                />
              </RadioGroup>
            </FormControl>

            <Box my={4}>
              <Typography gutterBottom>
                Los datos personales y de la empresa que nos facilites serán
                tratados por ASOCIACIÓN ADALAB como responsable de tratamiento
                con la finalidad de encontrar el perfil de alumna más adecuado a
                las necesidades de la empresa. Para ejercer tus derechos y más
                información consulta nuestra{' '}
                <a
                  target="_blank"
                  href="https://adalab.es/politica-de-privacidad/"
                  rel="noopener noreferrer"
                >
                  política de privacidad
                </a>
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={credentials.rgpdConsent}
                    value={credentials.rgpdConsent}
                    onChange={handleRGPD}
                  />
                }
                label={
                  'Acepto el tratamiento de mis datos con la finalidad indicadas y según la política de privacidad'
                }
              />
            </Box>

            <Box mt={2}>
              <LoadingButton
                text="Crear cuenta de empresa"
                disabled={!credentials.rgpdConsent}
                loading={loading || user}
              />
            </Box>
          </>
        </form>
      </Box>
    </Container>
  )
}

const SignUpLink = () => (
  <Typography variant="body1" gutterBottom>
    ¿No tienes cuenta? <Link to="signup">Regístrate</Link>
  </Typography>
)

export default withRouter(SignUpForm)
export { SignUpForm, SignUpLink }
