import React from 'react'
import { useModal } from 'react-modal-hook'
import Confetti from 'react-confetti/dist/react-confetti'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core'
import { SignUpImg } from 'components/UI/common'
import JoyRide from 'components/UI/Illustrations/undraw_joyride.svg'


export default function NewAdalaber({user}) {
  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <>
        <Dialog
          maxWidth="xs"
          open={open}
          onExited={onExited}
          onClose={hideModal}
        >
          <DialogTitle align="center">
            <Typography component="h2" variant="h3">¡Hola, {user.profile.fullName}!</Typography>
          </DialogTitle>
          <DialogContent align="center">
            <SignUpImg src={JoyRide} />
            <Typography gutterBottom>Te damos la bienvenida a <strong>Emma</strong>, la plataforma de empleo de Adalab. Por favor, rellena tu perfil y muy pronto, podrás acceder a las ofertas de trabajo en nuestra plataforma.</Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={hideModal} color="primary">
              OK!
            </Button>
          </DialogActions>
        </Dialog>
        <Confetti style={{ zIndex: '999' }} />
      </>
    ),
    [user]
  )
  
  /* eslint-disable */
  React.useEffect(() => showModal(true), [])
  /* eslint-enable */

  return null
}
