import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const rootFolder = 'adalabers';
// const matchedFolder = 'recomendadas';
// const nonMatchedFolder = 'otras';

export const getCVZip = (adalabers) => {
  const zip = JSZip().folder(rootFolder)
  const title = 'adalabers'

  const cvs1 = adalabers.map(adalaber => fetch(adalaber.cvURL))
  const cvs2 = adalabers.map(adalaber => fetch(adalaber.cvEnURL))

  const allCVs1 = Promise.all(cvs1).then(responses => {
    const folder = zip.folder(`${rootFolder}-cvs`)
    responses.map((response, i) =>
      folder.file(adalabers[i].fullName + ' (es).pdf', response.blob())
    )
  })

  const allCVs2 = Promise.all(cvs2).then(responses => {
    const folder = zip.folder(`${rootFolder}-cvs`)
    responses.map((response, i) =>
      folder.file(adalabers[i].fullName + ' (en).pdf', response.blob())
    )
  })

  Promise.all([
    allCVs1,
    allCVs2
  ]).then(() =>
    zip
      .generateAsync({ type: 'blob' })
      .then(content => saveAs(content, title + '-cvs.zip'))
  )
}

// export const getCVZip = (matched, nonMatched, employer) => {
//   const zip = JSZip().folder(rootFolder);

//   const matchedPromises = matched.map(adalaber => fetch(adalaber.cvURL));
//   const nonMatchedPromises = nonMatched.map(adalaber => fetch(adalaber.cvURL));

//   const allMatchedPromise = Promise.all(matchedPromises).then(responses => {
//     const folder = zip.folder(matchedFolder);
//     responses.map((response, i) =>
//       folder.file(matched[i].fullName + '.pdf', response.blob())
//     );
//   });
//   const allNonMatchedPromise = Promise.all(nonMatchedPromises).then(
//     responses => {
//       const folder = zip.folder(nonMatchedFolder);
//       responses.map((response, i) =>
//         folder.file(nonMatched[i].fullName + '.pdf', response.blob())
//       );
//     }
//   );

//   Promise.all([allMatchedPromise, allNonMatchedPromise]).then(() =>
//     zip
//       .generateAsync({ type: 'blob' })
//       .then(content => saveAs(content, employer.name + '-cvs.zip'))
//   );
// };
