import React from 'react'
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom'

import { useSession } from 'fb/auth'
import { auth } from 'fb'

import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'

import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import LoadingButton from '../../components/UI/LoadingButton'

import FormHeader from 'components/UI/FormHeader'

const SignUpForm = props => {
  const user = useSession()

  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [credentials, setCredentials] = React.useState({
    fullName: '',
    email: '',
    password: '',
    showPassword: true,
    rgpdConsent: false
  })

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      // HERE
      await auth.doCreateUserWithEmailAndPassword(
        credentials.email,
        credentials.password,
        credentials.fullName
      )
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    setLoading(false)
  }

  const handleChange = prop => event => {
    setCredentials({ ...credentials, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setCredentials({
      ...credentials,
      showPassword: !credentials.showPassword
    })
  }

  const handleRgpd = () => {
    setCredentials({
      ...credentials,
      rgpdConsent: !credentials.rgpdConsent
    })
  }

  if (user) return <Redirect to={'/'} />

  return (
    <Container disableGutters component="main" maxWidth="xs">
      <FixedLinearProgress isLoading={loading} />

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="stretch"
        height="100%"
      >
        <FormHeader icon="lock" title="Crear cuenta Adalaber">
          <Link
            component={RouterLink}
            to="/signup"
            variant="body2"
            align="center"
          >
            ¿Quieres crear una cuenta de empresa? Haz click aquí!
          </Link>
          <br />
        </FormHeader>

        <form onSubmit={handleSubmit}>
          <TextField
            onChange={handleChange('fullName')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="fullName"
            label="Nombre Completo"
            name="fullName"
            autoComplete="name"
            autoFocus
          />
          <TextField
            onChange={handleChange('email')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
          />
          <TextField
            onChange={handleChange('password')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            value={credentials.password}
            type={credentials.showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Enseñar o esconder contraseña"
                    onClick={handleClickShowPassword}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {credentials.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Box my={4}>
            <Typography gutterBottom>
              Los datos personales que nos facilites serán tratados por
              ASOCIACIÓN ADALAB como responsable de tratamiento con la única
              finalidad de gestionar tu perfil en nuestra Bolsa de Empleo. Para
              ello tus datos serían facilitados a aquellas empresas a cuyas
              ofertas apliques. Para ejercer tus derechos y más información
              consulta nuestra{' '}
              <a
                target="_blank"
                href="https://adalab.es/politica-de-privacidad/"
                rel="noopener noreferrer"
              >
                política de privacidad
              </a>
            </Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={credentials.rgpdConsent}
                  value={credentials.rgpdConsent}
                  onChange={handleRgpd}
                />
              }
              label={
                'Acepto el tratamiento de mis datos con la finalidad indicadas y según la política de privacidad'
              }
            />
          </Box>

          <Box mt={2}>
            <LoadingButton
              text="Crear cuenta"
              disabled={!credentials.rgpdConsent}
              loading={loading || user}
            />
          </Box>
        </form>

        <Box
          pt={4}
          pb={4}
          display="flex"
          component="footer"
          justifyContent="space-between"
        >
          <Link
            className="blue"
            component={RouterLink}
            to="/pw-forget"
            variant="body2"
          >
            Olvidaste tu contraseña?
          </Link>
          <Link
            component={RouterLink}
            to="/signin"
            variant="body2"
            align="right"
            className="blue"
          >
            ¿Ya tienes cuenta? Inicia sesión
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

const SignUpLink = () => (
  <Typography variant="body1" gutterBottom>
    ¿No tienes cuenta? <Link to="signup">Regístrate</Link>
  </Typography>
)

export default withRouter(SignUpForm)
export { SignUpForm, SignUpLink }
