import React from 'react'
import NuevaOfertaCard from './dashboard/NuevaOfertaCard'
import Profile from './dashboard/Profile'
import VerOfertasCard from './dashboard/VerOfertasCard'

import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActionArea,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Money from 'components/UI/CustomIcons/currency.svg'
import Contract from 'components/UI/CustomIcons/licensing.svg'
import Business from 'components/UI/CustomIcons/exchange.svg'
import article1 from '../../assets/Article1.jpg'
import article2 from '../../assets/Article2.png'
import amazon from '../../assets/Amazon.png'
import accenture from '../../assets/Accenture.png'
import carrefour from '../../assets/Carrefour.png'
import openbank from '../../assets/Openbank.png'
import bbva from '../../assets/BBVA.png'
import journey from 'components/UI/Illustrations/undraw_journey.svg'
import coder from 'components/UI/Illustrations/undraw_proud_coder.svg'

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    marginTop: 16,
    paddingTop: '56.25%' // 16:9
  }
}))

export default function Dashboard({ areThereOpps }) {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignItems="stretch"
    >
      <Grid item sm="auto" md={3} style={{ position: 'relative' }}>
        <Profile />
      </Grid>
      <Grid
        md={9}
        item
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="stretch"
      >
        <Grid item md={areThereOpps ? 4 : 8}>
          <NuevaOfertaCard />
        </Grid>

        {areThereOpps && (
          <Grid item md={4}>
            <VerOfertasCard />
          </Grid>
        )}

        <Grid item md={4}>
          <Card>
            <CardActionArea
              component="a"
              href="https://medium.com/adalab/las-adalabers-est%C3%A1n-preparadas-para-trabajar-en-remoto-desde-el-principio-rotundamente-s%C3%AD-8823aae1caf8"
              target="_blank"
            >
              <CardHeader title="Que la distancia geográfica no te pare" />
              <CardMedia
                className={classes.media}
                image={journey}
                title="Trabajando en remoto"
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="body2" gutterBottom>
                Tenemos adalabers con interés en trabajar en cualquier lugar, y
                solo aplicarán a tu oferta las que estén viviendo en tu ciudad o
                estén dispuestas a moverse. Además, en Adalab hacemos mucho
                énfasis en el trabajo en remoto y nuestras alumnas, aunque
                junior, están listas para incorporarse a trabajar directamente
                en remoto.
              </Typography>

              <Typography
                style={{ marginTop: 16, display: 'block' }}
                component="a"
                target="_blank"
                href="https://adalab.es/las-adalabers-estan-preparadas-para-trabajar-en-remoto-rotundamente-si/"
                variant="h6"
              >
                Seguir leyendo →
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid variant="outlined" item md={5}>
          <Card>
            <CardActionArea
              component="a"
              href="https://medium.com/adalab/en-adalab-somos-agile-y-as%C3%AD-se-lo-ense%C3%B1amos-a-nuestras-alumnas-e8985c92f020"
              target="_blank"
            >
              <CardHeader title="El perfil de las adalabers" />
              <CardMedia
                className={classes.media}
                image={coder}
                title="Proud coder"
              />
            </CardActionArea>

            <CardContent>
              <Typography variant="body2" gutterBottom>
                Todas las alumnas recién graduadas de Adalab son{' '}
                <strong>Programadoras Web o Data Analysts junior</strong>, con
                una formación técnica de calidad y con altas capacidades de
                autoaprendizaje, ganas de seguir formándose y mentalidad agile.
              </Typography>

              <Typography
                style={{ marginTop: 16, display: 'block' }}
                component="a"
                target="_blank"
                href="https://adalab.es/como-es-una-adalaber-desvelamos-el-perfil-de-nuestras-alumnas/"
                variant="h6"
              >
                Seguir leyendo →
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={7}>
          <Card>
            <CardHeader title="Algunos datos interesantes" />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar variant="square" src={Money} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="El 90% de las alumnas graduadas en Adalab desde su creación en 2016"
                    secondary="ha encontrado empleo en el sector tecnológico"
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar variant="square" src={Contract} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="220 alumnas se han insertado desde marzo 2020"
                    secondary="a pesar de la crisis sanitaria, el 71% en remoto"
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={Business} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Más de 230 empresas"
                    secondary="han contratado ya alumnas de Adalab"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={6}>
          <Card>
            <CardActionArea
              component="a"
              href="https://adalab.es/yo-he-contratado-una-adalaber-en-mi-empresa-y-te-animo-a-que-tu-tambien-lo-hagas/"
              target="_blank"
            >
              <CardHeader title="Yo he contratado una Adalaber en mi empresa, ¡y te animo a que tú también lo hagas!" />
              <CardMedia
                className={classes.media}
                image={article2}
                title="Speed dating"
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="body2" gutterBottom>
                El 90% de todas las alumnas de Adalab consiguieron su primer
                empleo como programadoras tras finalizar el bootcamp. ¿Qué
                tienen las adalabers que todas las empresas quieren tenerlas en
                sus equipos? La respuesta...
              </Typography>
              <Typography
                style={{ marginTop: 16, display: 'block' }}
                component="a"
                target="_blank"
                href="https://adalab.es/yo-he-contratado-una-adalaber-en-mi-empresa-y-te-animo-a-que-tu-tambien-lo-hagas/"
                variant="h6"
              >
                Seguir leyendo →
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={6}>
          <Card>
            <CardActionArea
              component="a"
              href="https://adalab.es/que-puede-aportar-una-adalaber-a-mi-equipo/"
              target="_blank"
            >
              <CardHeader title="¿Qué puede aportar una Adalaber a mi equipo? Tenemos el talento que necesitas." />
              <CardMedia
                className={classes.media}
                image={article1}
                title="Adalabers escuchando a una empresa"
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="body2" gutterBottom>
                Las empresas tecnológicas tienen ante ellas un gran desafío:
                encontrar talento preparado para hacer frente a sus retos. Dar
                con profesionales formados y motivados, que además aporten
                diversos enfoques y virtudes...
              </Typography>
              <Typography
                style={{ marginTop: 16, display: 'block' }}
                component="a"
                target="_blank"
                href="https://adalab.es/que-puede-aportar-una-adalaber-a-mi-equipo/"
                variant="h6"
              >
                Seguir leyendo →
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={6}>
          <Card>
            <CardHeader title="Descubre nuestras empresas colaboradoras" />
            <CardContent>
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={4}
                  alignItems="center"
                  justify="center"
                  style={{ display: 'flex' }}
                >
                  <img style={{ maxWidth: '110px' }} src={amazon} />
                </Grid>
                <Grid
                  item
                  xs={4}
                  alignItems="center"
                  justify="center"
                  style={{ display: 'flex' }}
                >
                  <img style={{ maxWidth: '120px' }} src={accenture} />
                </Grid>
                <Grid
                  item
                  xs={4}
                  alignItems="center"
                  justify="center"
                  style={{ display: 'flex' }}
                >
                  <img style={{ maxWidth: '100px' }} src={carrefour} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  alignItems="center"
                  justify="center"
                  style={{ display: 'flex' }}
                >
                  <img style={{ maxWidth: '140px' }} src={openbank} />
                </Grid>
                <Grid
                  item
                  xs={6}
                  alignItems="center"
                  justify="center"
                  style={{ display: 'flex' }}
                >
                  <img style={{ maxWidth: '120px' }} src={bbva} />
                </Grid>
              </Grid>
              <Typography
                style={{ marginTop: 16, display: 'block' }}
                component="a"
                target="_blank"
                href="https://adalab.es/empresas/#:~:text=Ya%20cuentan%20con%20adalabers"
                variant="h6"
              >
                Ver más empresas →
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={6}>
          <Card>
            <CardHeader title="Voluntariado Corporativo" />
            <CardContent>
              <Typography variant="body2" gutterBottom>
                Si además de contratar alumnas, quieres ser parte de nuestra
                programa de voluntariado corporativo, descárgate nuestro Dossier
                Informativo.
              </Typography>
              <Typography
                style={{ marginTop: 16, display: 'block' }}
                component="a"
                target="_blank"
                href="https://drive.google.com/file/d/1s0n2rG-kxaRxvXfFPS0v1PB8dPAIKIgq"
                variant="h6"
              >
                Descargar →
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}
