import React from 'react'
import { Link } from 'react-router-dom'
import * as ROUTES from 'constants/routes'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button
} from '@material-ui/core'

export default function VerOfertasCard() {
  return (
    <Card>
      <CardHeader title="Ver ofertas y candidatas" />
      <CardContent>
        <Typography gutterBottom>
          Aquí podrás ver tus ofertas publicadas. Adalab las revisará y validará
          en un plazo máximo de 48 horas y una vez validadas, no podrás
          editarlas. Posteriormente, Adalab te enviará un mail para avisar de
          que ya se pueden consultar las candidatas interesadas en las ofertas.
          Para ello, solo tendrás que pinchar en cada oferta
        </Typography>
        <br />
        <Button
          component={Link}
          to={ROUTES.OFFERS}
          size="large"
          variant="contained"
          color="primary"
        >
          <span style={{ color: 'white' }}>Ver mis ofertas</span>
        </Button>
      </CardContent>
    </Card>
  )
}
