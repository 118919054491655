export const SIGN_UP = '/signup'
export const SIGN_UP_ADALABERS = '/signup/adalabers'
export const SIGN_UP_EMPRESAS = '/signup/empresas'
export const SIGN_IN = '/signin'
export const SIGN_IN_2 = '/signin2'
export const PASSWORD_FORGET = '/pw-forget'

export const LANDING = '/'
export const HOME = '/home'
export const PROFILE = HOME + '/profile'
export const OFFERS = '/ofertas'
export const ADALABER = '/adalaber'
export const OFFERSADALABER = ADALABER + OFFERS

// export const EMPLOYERHOME = '/empresa'
// export const EMPLOYERPROFILE = EMPLOYERHOME + '/profile'
// export const OFFERS = HOME + '/offers'

export const ADMIN = '/admin'
export const EMPLOYERS2 = ADMIN + '/employers2'
export const EMPLOYERS = ADMIN + '/employers'
export const ADALABERS = ADMIN + '/adalabers'
export const ADMINOFFERS = ADMIN + '/adalabers/ofertas'

export const ADMINONLYADALABER = 'adalaber'
export const ADMINONLYOFFERS = 'ofertas'
