import React from 'react';
import { Typography, Box } from '@material-ui/core';
import SmilingGirl from './CustomIcons/SmilingGirl'

const AdaFormHeader = ({ icon, title, children }) => {
  return (
    <Box
      component="header"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {icon && (
        <SmilingGirl style={{ fontSize: '2.618rem', fill: 'var(--adalabBlue)', marginBottom: '0.3rem' }} />
      )}

      <Typography component="h1" variant="h5" style={{ fontSize: '1.7rem' }}>
        {title}
      </Typography>

      {children}
    </Box>
  );
};

export default AdaFormHeader;
