import React from 'react'
import { useModal } from 'react-modal-hook'
import MaterialTable from 'material-table'
import { tableIcons } from './partials/TableIcons'
import {
  Toolbar,
  Typography,
  Box,
  Avatar,
  Chip,
  Button,
  Tooltip
} from '@material-ui/core'
import { IconButton, Dialog } from '@material-ui/core'
import {
  NotInterested,
  Close,
  CheckCircle,
  CheckCircleOutline,
  CloudDownload,
  Undo,
  FilterList
} from '@material-ui/icons'
import AdalaberDetail from './AdalaberDetail'
import { PromoAvatar } from 'components/UI/common'
import * as ZIP from '../../zip/'
import { db } from '../../fb/firebase'

const AdalabersList = ({ offer, OfferID, adalabers }) => {
  const [filtering, setFiltering] = React.useState(false)
  const [activeAdalaber, setActiveAdalaber] = React.useState(null)
  const [data, setData] = React.useState(() => {
    return adalabers.map(a => {
      return {
        ...a,
        isDiscarded: a?.discarded?.includes(OfferID),
        isIncludedInProcess: a?.includedInProcess?.includes(OfferID)
      }
    })
  })

  let englishLevels = [
    ...new Set(
      adalabers.filter(ada => ada.englishLevel).map(ada => ada.englishLevel)
    )
  ].sort()
  englishLevels = englishLevels.reduce((acc, cur, i) => {
    acc[cur] = cur
    return acc
  }, {})

  let messagesForEmployer = {
    'Con mensaje': <CheckCircle color="primary" />
  }

  const containsSearchTerms = (term, data) => {
    const searchArr = term
      .toLowerCase()
      .trim()
      .split(' ')
    const lowerData = data.toLowerCase()
    const match = searchArr.every(x => lowerData.includes(x))
    return match
  }

  const [state] = React.useState({
    columns: [
      {
        title: 'Promo',
        field: 'promo',
        filtering: false,
        sorting: false,
        width: '3em',
        render: rowData => (
          <Tooltip title={rowData.promo}>
            <PromoAvatar>{rowData.promo[0]}</PromoAvatar>
          </Tooltip>
        )
      },
      {
        title: '',
        field: 'avatar',
        filtering: false,
        sorting: false,
        width: '3em',
        render: rowData => (
          <Avatar
            src={rowData.avatar}
            style={{ width: '2.618em', height: '2.618em' }}
          />
        )
      },
      {
        title: 'Adalaber',
        field: 'fullName',
        render: rowData => (
          <Box>
            <Typography variant="body1" style={{ fontWeight: 'normal' }}>
              {rowData.fullName}
            </Typography>
            <Typography variant="body2">{rowData.email}</Typography>
          </Box>
        ),
        customFilterAndSearch: (term, rowData) =>
          containsSearchTerms(term, rowData.fullName) ||
          containsSearchTerms(term, rowData.email)
      },
      {
        title: 'Nivel de inglés',
        field: 'englishLevel',
        lookup: englishLevels,
        render: rowData =>
          rowData.englishLevel && (
            <Chip
              color="primary"
              label={rowData.englishLevel}
              data-color={rowData.englishLevel}
            />
          )
      },
      {
        title: 'Mensaje',
        field: 'messageForEmployer',
        width: '2em',
        lookup: messagesForEmployer,
        customFilterAndSearch: (term, rowData) => {
          if (
            term.includes('Con mensaje') &&
            rowData.messageForEmployer &&
            rowData.messageForEmployer[OfferID]
          )
            return true
          if (!term.length) return true
          else return false
        },
        render: rowData =>
          rowData.messageForEmployer &&
          rowData.messageForEmployer[OfferID] && <CheckCircle color="primary" />
      }
    ],
    data: data
  })

  const setAndOpen = id => {
    const adalaber = adalabers.find(adalaber => adalaber.id === id)
    Promise.resolve(setActiveAdalaber(adalaber)).then(() => showModal())
  }

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Dialog maxWidth="xs" open={open} onExited={onExited} onClose={hideModal}>
        <IconButton
          style={{ margin: '1em', position: 'absolute', right: '0', top: '0' }}
          aria-label="close"
          onClick={hideModal}
        >
          <Close />
        </IconButton>

        <AdalaberDetail OfferID={OfferID} adalaber={activeAdalaber} />
      </Dialog>
    ),
    [activeAdalaber]
  )

  if (offer && !offer.accepted) return null

  return (
    <>
      <MaterialTable
        localization={{
          header: {
            actions: 'Acciones'
          }
        }}
        icons={tableIcons}
        options={{
          filtering: true,
          filterCellStyle: filtering ? {} : { display: 'none' },
          sorting: true,
          paging: false,
          search: false,
          headerStyle: { fontSize: '110%' }
        }}
        title="Candidatas"
        columns={state.columns}
        data={data}
        style={{ width: '100%', height: '100%', overflow: 'scroll' }}
        onRowClick={(e, rowData) => setAndOpen(rowData.id)}
        actions={[
          rowData => {
            return {
              disabled: rowData.isIncludedInProcess,
              tooltip: 'Incluir en el proceso de selección',
              icon: () => {
                return <CheckCircleOutline />
              },
              onClick: async (event, rowData) => {
                setData(prev => {
                  const filtered = prev.filter(a => a.id !== rowData.id)

                  return [
                    ...filtered,
                    {
                      ...rowData,
                      isIncludedInProcess: true,
                      isDiscarded: false
                    }
                  ]
                })

                await db
                  .collection('adalabers')
                  .doc(rowData.id)
                  .set(
                    {
                      includedInProcess: [
                        ...(rowData?.includedInProcess || []),
                        OfferID
                      ],
                      discarded:
                        rowData?.discarded?.filter(a => a !== OfferID) || []
                    },
                    { merge: true }
                  )
              }
            }
          },
          rowData => {
            return {
              disabled: rowData.isDiscarded,
              tooltip: 'Descartar candidata',
              icon: () => {
                return <NotInterested />
              },
              onClick: async (event, rowData) => {
                setData(prev => {
                  const filtered = prev.filter(a => a.id !== rowData.id)
                  return [
                    ...filtered,
                    {
                      ...rowData,
                      isDiscarded: true,
                      isIncludedInProcess: false
                    }
                  ]
                })

                await db
                  .collection('adalabers')
                  .doc(rowData.id)
                  .set(
                    {
                      discarded: [...(rowData?.discarded || []), OfferID],
                      includedInProcess:
                        rowData?.includedInProcess?.filter(
                          a => a !== OfferID
                        ) || []
                    },
                    { merge: true }
                  )
              }
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          rowStyle: rowData => {
            const opacity = rowData.isDiscarded ? 0.5 : 1
            const backgroundColor = rowData.isIncludedInProcess
              ? '#dcfdf7'
              : 'white'

            return { opacity: opacity, backgroundColor: backgroundColor }
          }
        }}
        components={{
          Toolbar: props => (
            <Toolbar>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={2}
              >
                <Typography variant="h2">Candidatas interesadas</Typography>

                <Box>
                  <Button
                    onClick={() => ZIP.getCVZip(adalabers)}
                    variant="outlined"
                    color="primary"
                    className="button"
                  >
                    Descargar CVs <CloudDownload className="right-icon" />
                  </Button>
                  <IconButton onClick={() => setFiltering(!filtering)}>
                    <FilterList />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          )
        }}
      />
    </>
  )
}

export default AdalabersList
