import React from 'react';
import { withRouter } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';
import { auth } from 'fb';

const withAuthorization = authCondition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      auth.onAuthUserListener(
        authUser => {
          if (!authCondition(authUser)) {
            this.props.history.push('/');
          }
        },
        () => this.props.history.push('/')
      );
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? <Component authUser={authUser} {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
