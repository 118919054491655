import React from 'react'
import Avatar from 'react-avatar-edit'
import { Box } from '@material-ui/core'

class AvatarEdit extends React.Component {
  constructor(props) {
    super(props)
    // const src = props.src
    this.state = {
      preview: null,
      // src
    }
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
  }

  componentDidMount() {
    // console.log('MOUNTIN')
    this.setState({src: this.props.src})
  }

  onClose() {
    this.setState({ preview: null })
  }

  onCrop(preview) {
    this.setState({ preview })
    this.props.saveAvatarImg(preview)
  }

  onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > this.props.maxSize) {
      alert('File is too big!')
      elem.target.value = ''
    }
  }

  render() {
    return (
      <Box display="flex" alignItems="center">
        <div>
          <Avatar
            width={390}
            height={290}
            onCrop={this.onCrop}
            onClose={this.onClose}
            onBeforeFileLoad={this.onBeforeFileLoad}
            src={this.state.src}
            label="Elige una imagen"
            borderStyle={{
              border: '2px dashed var(--adalabBlue)',
              display: 'flex',
              alignItems: 'center'
            }}
            labelStyle={{
              fontFamily: 'Roboto, sans-serif',
              userSelect: 'none',
              fontWeight: 'lighter',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer'
            }}
          />
        </div>
        {
          this.state.preview && <img style={{ marginLeft: '1em' }} src={this.state.preview} alt="Preview" />
        }
      </Box>
    )
  }
}

export default AvatarEdit