import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import config from 'config'

import { withRouter } from 'react-router-dom'

import { SimpleTable } from './List'
import * as STATUS from 'constants/status'

import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Toolbar,
  Fab,
  Container,
  Box,
  Input,
  Checkbox,
  ListItemText
} from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { getTheDate } from 'hooks'

import { download } from 'zip/csv'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import { loadCldr, L10n } from '@syncfusion/ej2-base'
import moment from 'moment'

const arrayNumericFields = ['accepted', 'contacted', 'adalabers']
const arrayDateFields = ['requestDate', 'acceptanceDeadline']

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/es/ca-gregorian.json'),
  require('cldr-data/main/es/numbers.json'),
  require('cldr-data/main/es/timeZoneNames.json')
)

L10n.load({
  es: {
    daterangepicker: {
      applyText: 'Aplicar',
      cancelText: 'Cancelar',
      customRange: 'Personalizado',
      days: 'Días',
      endLabel: 'Fecha de finalización',
      placeholder: 'Selecciona las fechas',
      selectedDays: 'Días seleccionados',
      startLabel: 'Fecha de inicio'
    }
  }
})

const SearchableList = props => {
  const [employers, setEmployers] = useState(props.employers || [])
  const [isLoading, setIsLoading] = useState(true)
  const [orderBy, setOrderBy] = useState('requestDate')
  const [desc, setDesc] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [status, setStatus] = useState(STATUS.ALL)
  const { promo } = props

  useEffect(() => {
    const employers = loadEmployers()
    setEmployers(employers)
  }, [])

  useEffect(
    () => {
      setIsLoading(false)
    },
    [employers]
  )

  useEffect(
    () => {
      const orderedEmployers = orderEmployers(employers)
      setEmployers(orderEmployers)
    },
    [desc, orderBy]
  )

  const orderEmployers = employers => {
    if (arrayNumericFields.includes(orderBy)) {
      return employers.sort((a, b) =>
        desc === true
          ? b[orderBy]?.length - a[orderBy]?.length
          : a[orderBy]?.length - b[orderBy]?.length
      )
    } else if (arrayDateFields.includes(orderBy)) {
      return employers.sort((a, b) => {
        let aDate = a[orderBy],
          bDate = b[orderBy]
        if (!aDate) {
          aDate = desc ? '1-1-2100' : '1-1-1970'
        }
        if (!bDate) {
          bDate = desc ? '1-1-2100' : '1-1-1970'
        }

        return desc === true
          ? getTheDate(aDate).getTime() - getTheDate(bDate).getTime()
          : getTheDate(bDate).getTime() - getTheDate(aDate).getTime()
      })
    } else if (orderBy === 'status') {
      return employers.sort((a, b) => {
        if (a[orderBy] === b[orderBy])
          return desc
            ? b.requestDate - a.requestDate
            : a.requestDate - b.requestDate
        return desc
          ? STATUS.ALL.indexOf(a[orderBy]) - STATUS.ALL.indexOf(b[orderBy])
          : STATUS.ALL.indexOf(b[orderBy]) - STATUS.ALL.indexOf(a[orderBy])
      })
    } else {
      return employers.sort((a, b) => {
        const one = desc ? a : b
        const two = desc ? b : a

        const first = one[orderBy] && one[orderBy].toLowerCase()
        const second = two[orderBy] && two[orderBy].toLowerCase()
        if (first === second) return one.requestDate - two.requestDate
        return second < first ? 1 : -1
      })
    }
  }

  const loadEmployers = () => {
    const empls = props.employers
      .filter(c => {
        return !status || (c.status && status.includes(c.status))
      })
      .filter(c => c.status)
      .map(employer => {
        const cvSentEvent =
          employer.statusHistory &&
          employer.statusHistory
            .sort((a, b) =>
              new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1
            )
            .find(s => s.status === STATUS.PLAZO_CERRADO)

        const isModernPromo = !config.oldpromos.includes(promo)

        return {
          ...employer,
          cvSentDate: cvSentEvent && cvSentEvent.timestamp,
          adalabers: props.adalabers
            .filter(
              a => employer.adalabers && employer.adalabers.includes(a.id)
            )
            .map(a => a.fullName),
          accepted:
            isModernPromo && employer.accepted
              ? employer.accepted
                  .map(ada => props.adalabers.find(ad => ad.id === ada))
                  .map(ada => (ada ? ada.fullName : ''))
              : props.adalabers
                  .filter(a => a.accepted && a.accepted.includes(employer.id))
                  .map(a => a.fullName),
          contacted: props.adalabers
            .filter(a => a.contacted && a.contacted.includes(employer.id))
            .map(a => a.fullName)
        }
      })

    return orderEmployers(empls)
  }

  const handleChange = event => {
    const { name, value } = event.target
    const allEmpl = loadEmployers()
    const newEmployers = allEmpl.filter(c => value.includes(c.status))
    console.log(value)
    setStatus(value)
    setIsLoading(true)
    setEmployers(newEmployers)
  }

  const handleChangeOrder = field => event => {
    setOrderBy(prevField => {
      const newDesc = prevField === field ? !desc : true

      setDesc(newDesc)

      return field
    })
  }

  const handleChangeDatePicker = params => {
    setIsLoading(true)

    setStartDate(params.startDate)
    setEndDate(params.endDate)
    const employers = loadEmployers()

    let newEmployers = employers.filter(item => {
      const requestDate =
        typeof item.requestDate === 'string'
          ? new Date(item.requestDate)
          : item?.requestDate?.toDate()

      return requestDate >= params.startDate && requestDate <= params.endDate
    })

    if (params.startDate == null && params.endDate == null) {
      newEmployers = employers
    }

    setEmployers(newEmployers)
  }

  return (
    <Container>
      <Toolbar>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={2}
        >
          <div style={{ width: '200px' }}>
            <DateRangePickerComponent
              id="daterangepicker"
              locale="es"
              change={handleChangeDatePicker}
              cssClass="customCSS"
              max={new Date()}
              startDate={startDate}
              endDate={endDate}
              presets={[
                {
                  label: 'Últimos 7 dias',
                  start: new Date(new Date().setDate(new Date().getDate() - 7)),
                  end: new Date()
                },
                {
                  label: 'Este mes',
                  start: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    1
                  ),
                  end: new Date()
                },
                {
                  label: 'El mes pasado',
                  start: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 1,
                    1
                  ),
                  end: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    0
                  )
                },
                {
                  label: 'Últimos 3 meses',
                  start: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 3,
                    1
                  ),
                  end: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    0
                  )
                },
                {
                  label: '2021',
                  start: new Date(new Date().getFullYear(), 0, 1),
                  end: new Date(new Date().getFullYear(), 12, 0)
                },
                {
                  label: '2020',
                  start: new Date(new Date().getFullYear() - 1, 0, 1),
                  end: new Date(new Date().getFullYear() - 1, 12, 0)
                },
                {
                  label: '2019',
                  start: new Date(new Date().getFullYear() - 2, 0, 1),
                  end: new Date(new Date().getFullYear() - 2, 12, 0)
                }
              ]}
            />
          </div>
          <form action="" className="search-form">
            <FormControl>
              <InputLabel shrink htmlFor="status" className="select--label">
                Estado
              </InputLabel>
              <Select
                labelId="status"
                name="status"
                id="status"
                multiple
                value={status}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected.join(', ')}
              >
                {STATUS.ALL.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={status.includes(name)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
            onClick={download(
              employers.map(employer => ({
                ...employer,
                requestDate: `= ${moment(
                  getTheDate(employer.requestDate)
                ).format('X')}/86400+DATE(1970;1;1)`
              })),
              'employers',
              ['name', 'status', 'requestDate', 'acceptanceDeadline'],
              ['accepted', 'contacted', 'adalabers']
            )}
          >
            <CloudDownload style={{ marginRight: '1em' }} />
            {employers.length} resultados
          </Fab>
        </Box>
      </Toolbar>
      {!isLoading && (
        <SimpleTable
          adalabers={props.adalabers}
          employers={employers}
          changeOrder={handleChangeOrder}
          orderBy={orderBy}
          desc={desc}
        />
      )}
    </Container>
  )
}

SearchableList.propTypes = {
  employers: PropTypes.arrayOf(PropTypes.object).isRequired,
  adalabers: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default withRouter(SearchableList)
