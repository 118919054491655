import React, { useState } from 'react';
import { db } from 'fb';
import { DialogContent, DialogActions, FormControl, FormLabel, Button } from '@material-ui/core'
import DatePicker from 'react-datepicker';

const Contratar = ({update, employer, adalaber, hideModal}) => {
  const [fecha, setFecha] = useState('')

  const saveInserted = async event => {
    event.preventDefault();
    await db.insertAdalaberInEmployer(employer, adalaber)
    await db.updateAdalaber(adalaber.id, { 'incorporationDate': fecha })
    hideModal()
  };

  return (
    <>
      <DialogContent>
        <FormControl
          component="fieldset"
          style={{ marginTop: '2em', marginBottom: '1em' }}
        >
          <FormLabel
            component="legend"
            style={{ marginBottom: '1em' }}
            htmlFor="incorporationDate"
          >
            Fecha de incorporación aproximada
            </FormLabel>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={fecha}
            onChange={date => setFecha(date)}
            id="incorporationDate"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal} color="secondary">
          Cancelar
        </Button>
        <Button onClick={saveInserted} color="primary">
          Go!
        </Button>
      </DialogActions>
    </>
  )
}

export default Contratar