import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import { withRouter } from 'react-router-dom'

import { SimpleTable } from './List'
import PromoSet from 'components/UI/PromoSet'

import {
  FormControlLabel,
  Checkbox,
  Toolbar,
  Fab,
  Container,
  Box
} from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'

import { download } from '../../../zip/csv'

const arrayNumericFields = ['accepted', 'matches', 'contacted']

class SearchableList extends React.Component {
  constructor(props) {
    super(props)
    const params = qs.parse(this.props.location.search.slice(1))
    this.state = {
      inserted: params.inserted || '',
      inactive: params.inactive || '',
      orderBy: params.orderBy || 'fullName',
      desc: true
    }
  }

  handleChange = event => {
    const { name, checked } = event.target

    this.props.history.push({
      search: qs.stringify({
        ...this.state,
        [name]: checked ? true : false
      })
    })
    this.setState({ [name]: checked ? true : false })
  }

  changeOrder = field => event => {
    event.preventDefault()
    this.props.history.push({
      search: qs.stringify({
        ...this.state,
        orderBy: field
      })
    })
    this.setState({
      orderBy: field
    })

    if (this.state.orderBy === field) {
      this.setState({ desc: !this.state.desc })
    } else this.setState({ desc: true })
  }

  filterAndOrderAdalabers = () => {
    const { inserted, inactive, orderBy } = this.state
    const { employers, adalabers } = this.props

    const caseInsensitiveCompareFunction = (a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())

    const filteredAdalabers = adalabers
      .filter(a => inserted || !(a.employer || a.employerOutOfNetwork))
      .filter(a => inactive || !a.inactive)
      .map((ada, i) => {
        const employerName = ada.employer
          ? employers &&
            employers.find(
              emp => emp.id === ada.employer || emp.employer === ada.employer
            ) &&
            employers.find(
              emp => emp.id === ada.employer || emp.employer === ada.employer
            ).name
          : ada.employerOutOfNetwork

        return {
          ...ada,
          employerName,
          accepted:
            employers
              .filter(e => ada.accepted && ada.accepted.includes(e.id))
              .map(a => a.name)
              .sort(caseInsensitiveCompareFunction) || ada.accepted,
          acceptedIDs: employers
            .filter(e => ada.accepted && ada.accepted.includes(e.id))
            .map(a => a.id),
          contacted: employers
            .filter(e => ada.contacted && ada.contacted.includes(e.id))
            .map(a => a.name)
            .sort(caseInsensitiveCompareFunction)
        }
      })

    if (orderBy === 'incorporationDate') {
      return filteredAdalabers.sort((a, b) => {
        const dateA = a.incorporationDate
          ? a.incorporationDate.getTime()
          : this.state.desc
          ? new Date('1970').getTime()
          : new Date('2099').getTime()
        const dateB = b.incorporationDate
          ? b.incorporationDate.getTime()
          : this.state.desc
          ? new Date('1970').getTime()
          : new Date('2099').getTime()

        let comparison = 0
        if (dateA > dateB) {
          comparison = 1
        } else if (dateA < dateB) {
          comparison = -1
        }

        return this.state.desc === true ? comparison * -1 : comparison
      })
    } else if (arrayNumericFields.includes(orderBy)) {
      return filteredAdalabers.sort((a, b) =>
        this.state.desc === true
          ? b[orderBy].length - a[orderBy].length
          : a[orderBy].length - b[orderBy].length
      )
    } else {
      return filteredAdalabers.sort((a, b) => {
        const one = this.state.desc ? a : b
        const two = this.state.desc ? b : a

        const first = (one[orderBy] && one[orderBy].toLowerCase()) || '~'
        const second = (two[orderBy] && two[orderBy].toLowerCase()) || '~'

        if (first === second) return b.fullName - a.fullName
        return second < first ? 1 : -1
      })
    }
  }

  render() {
    const { inserted, inactive, orderBy, desc } = this.state
    const { employers } = this.props
    const adalabers = this.filterAndOrderAdalabers()

    return (
      <Container>
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={2}
          >
            <form
              action=""
              className="search-form"
              style={{ transform: 'scale(.8)' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inserted ? true : false}
                    onChange={this.handleChange}
                    value="checked"
                    inputProps={{
                      name: 'inserted',
                      id: 'inserted'
                    }}
                  />
                }
                label="Mostrar insertadas"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inactive ? true : false}
                    onChange={this.handleChange}
                    value="checked"
                    inputProps={{
                      name: 'inactive',
                      id: 'inactive'
                    }}
                  />
                }
                label="Mostrar desactivadas"
              />
              <PromoSet />
            </form>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={download(
                adalabers,
                'adalabers',
                [
                  'id',
                  'fullName',
                  'acceptedIDs',
                  'email',
                  'tel',
                  'github',
                  'linkedin',
                  'shortBio',
                  'promo',
                  'englishLevel',
                  'studies',
                  'preferredCompanies',
                  'careerProjection',
                  'additionalLocations',
                  'remoteOK',
                  'reducedSchedule',
                  'problemsToWorkNow',
                  'comments',
                  'apprenticeshipContract',
                  'cvURL',
                  'employer',
                  'employerOutOfNetwork'
                ],
                ['accepted', 'matches', 'contacted']
              )}
            >
              <CloudDownload style={{ marginRight: '1em' }} />
              {adalabers.length} resultados
            </Fab>
          </Box>
        </Toolbar>
        <SimpleTable
          employers={employers}
          adalabers={adalabers}
          changeOrder={this.changeOrder}
          orderBy={orderBy}
          desc={desc}
        />
      </Container>
    )
  }
}

SearchableList.propTypes = {
  employers: PropTypes.arrayOf(PropTypes.object).isRequired,
  adalabers: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default withRouter(SearchableList)
