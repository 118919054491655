const prod =
  !window.location.href.includes('netlify') &&
  !window.location.href.includes('localhost') &&
  !window.location.href.includes('emma-demo')

const SLACK_ADA_URL = prod
  ? 'https://hooks.zapier.com/hooks/catch/2965889/od8eywv/'
  : 'https://jsonplaceholder.typicode.com/posts'

export const sendOppToAdalabers = (employer, acceptanceDeadline) =>
  fetch(SLACK_ADA_URL, {
    method: 'POST',
    body: JSON.stringify({
      employer,
      acceptanceDeadline
    })
  })

const SLACK_ADMIN_URL = prod
  ? 'https://hooks.zapier.com/hooks/catch/2965889/ok2ecam'
  : 'https://jsonplaceholder.typicode.com/posts'

export const sendOppToAdminSlack = data =>
  fetch(SLACK_ADMIN_URL, {
    method: 'POST',
    body: JSON.stringify({
      data
    })
  })

const SLACK_CHANNEL_INVITE_URL = prod
  ? 'https://hooks.zapier.com/hooks/catch/2965889/ok4uuvo/'
  : 'https://jsonplaceholder.typicode.com/posts'

export const inviteToSlackChannel = email =>
  fetch(SLACK_CHANNEL_INVITE_URL, {
    method: 'POST',
    body: JSON.stringify({
      email
    })
  })
