import React, { useState } from 'react'
import { useFirestore } from 'reactfire'
import omit from 'lodash.omit'

import { useSession } from 'fb/auth'
import config from 'config'
import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'
import { sendOppToAdminSlack } from 'zapier'

import {
  Container,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Checkbox,
  Box
} from '@material-ui/core'

import LoadingButton from 'components/UI/LoadingButton'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const workingModes = {
  REMOTE: '100% remoto',
  ON_SITE: '100% presencial',
  SEMI_PRESENTIAL: 'Semipresencial',
  TEMPORARY_REMOTE: 'Remoto situación sanitaria',
  OTHER: 'Otro'
}

const NewOffer = ({ children, hideModal, showSuccessModal }) => {
  const [loading, setLoading] = React.useState(false)
  const user = useSession()
  const firestore = useFirestore()
  const fieldValue = useFirestore.FieldValue
  const newDate = new Date()

  const [offer, setOffer] = useState({
    title: '',
    englishLevel: '',
    contractType: '',
    otherContractType: '',
    salaryRange: '',
    opportunityLocation: '',
    reducedSchedule: '',
    universitaryStudies: '',
    incorporationDate: '',
    apoyoEnLaEmpresa: '',
    positionSummary: '',
    positionRequirements: '',
    motivational: '',
    contactEmail: '',
    comments: '',
    checkInterest: false,
    requestDate: newDate,
    workingMode: '',
    workingModeOther: '',
    promo: '',
    employer: user.uid,
    status: 'Nueva',
    creationTimestamp: useFirestore.FieldValue.serverTimestamp()
  })
  const { enqueueSnackbar } = useSnackbar()
  const [currentPromo, setCurrentPromo] = useState()

  React.useEffect(() => {
    const docRef = firestore.collection('config').doc('general')

    docRef.get().then(doc => {
      const { currentPromo } = doc.data()

      setCurrentPromo(currentPromo)
      setOffer(prevOffer => ({ ...prevOffer, promo: currentPromo }))
    })
  }, [])

  const handleSubmit = async e => {
    console.log('Creando nueva oferta')
    e.preventDefault()

    try {
      setLoading(true)
      if (!user || !offer) return

      const newOffer = await firestore
        .collection('employerUsers')
        .doc(user.uid)
        .collection('opportunities')
        .add({
          ...offer,
          incorporationDate: offer.incorporationDate.toISOString()
        })

      await firestore
        .collection('employerUsers')
        .doc(user.uid)
        .update({
          opportunityIDs: fieldValue.arrayUnion(newOffer.id),
          currentPromo
        })

      const prod = !window.location.href.includes('netlify') && !window.location.href.includes('localhost') && !window.location.href.includes('emma-demo')

      if (prod) {
        await sendOppToAdminSlack({
          oferta: {
            ...offer,
            incorporationDate: offer.incorporationDate.toISOString()
          },
          empresa: user.profile
        })
      }

      showSuccessModal()
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
    setLoading(false)
    enqueueSnackbar('Nueva oferta creada', { variant: 'success' })
    hideModal()
  }

  const handleChange = prop => event => {
    setOffer({ ...offer, [prop]: event.target.value })
  }

  const handleDate = date => setOffer({ ...offer, incorporationDate: date })

  return <Container maxWidth="sm">
      <FixedLinearProgress isLoading={loading} />
      <form onSubmit={handleSubmit} style={{ padding: '0 0 1em' }}>
        <TextField onChange={handleChange('title')} variant="outlined" margin="normal" fullWidth id="title" label="0. Título de la oferta" helperText="Introduce un título descriptivo." name="title" value={offer.title} required={true} style={{ marginBottom: '2em' }} />
        <br />
        <FormControl component="fieldset" required>
          <FormLabel component="legend">
            1. ¿Qué tipo de candidata estás buscando?
          </FormLabel>
          <RadioGroup aria-label="bootcamp" name="bootcamp" value={offer.bootcamp || 'Programación web'} onChange={handleChange('bootcamp')}>
            <FormControlLabel value="Programación web" control={<Radio required={true} />} label="Programadora Web" />
            <FormControlLabel value="Data analyst" control={<Radio required={true} />} label="Data Analysts" />
          </RadioGroup>
        </FormControl>
        <br />
        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '0' }}>
            2. Indica por favor el nivel mínimo de inglés que las candidatas deben tener
          </FormLabel>
          <RadioGroup aria-label="englishLevel" name="englishLevel" value={offer.englishLevel} onChange={handleChange('englishLevel')} style={{ margin: 0 }}>
            <FormControlLabel value="No es necesario" control={<Radio required={true} />} label="No es necesario" />
            <FormControlLabel value="B1" control={<Radio required={true} />} label="B1" />
            <FormControlLabel value="B2" control={<Radio required={true} />} label="B2" />
            <FormControlLabel value="C1" control={<Radio required={true} />} label="C1" />
            <FormControlLabel value="C2" control={<Radio required={true} />} label="C2" />
          </RadioGroup>
        </FormControl>
        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend">
            3. Indica el tipo de contrato
          </FormLabel>
          <FormHelperText style={{ marginBottom: '1em' }}>
            No trabajamos con becas, prácticas ni contratos formativos
          </FormHelperText>
          <RadioGroup aria-label="contractType" name="contractType" value={offer.contractType} onChange={handleChange('contractType')}>
            <FormControlLabel value="Contrato indefinido" control={<Radio required={true} />} label="Contrato indefinido" />
            <FormControlLabel value="Contrato temporal (mínimo 6 meses)" control={<Radio required={true} />} label="Contrato temporal (mínimo 6 meses)" />
            <FormControlLabel value="Contrato de servicios (autónoma)" control={<Radio required={true} />} label="Contrato de servicios (autónoma)" />
            <FormControlLabel value="Otro" control={<Radio required={true} />} label="Otro" />
          </RadioGroup>
        </FormControl>
        <br />
        {offer.contractType == 'Otro' && <TextField onChange={handleChange('otherContractType')} variant="outlined" margin="normal" fullWidth id="otherContractType" label="Otro tipo de contrato" name="otherContractType" value={offer.otherContractType} required />}
        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend">
            4. Indica la escala salarial que ofrece la empresa
          </FormLabel>
          <RadioGroup helperText="Por favor indicar el salario bruto anual. Recuerda que en Adalab no trabajamos con ofertas con un rango salarial a los 16.000 €" aria-label="salaryRange" name="salaryRange" value={offer.salaryRange} onChange={handleChange('salaryRange')}>
            <FormControlLabel value="16.000 - 18.000 €" control={<Radio required={true} />} label="16.000 - 18.000 €" />
            <FormControlLabel value="18.000 - 20.000 €" control={<Radio required={true} />} label="18.000 - 20.000 €" />
            <FormControlLabel value="20.000 - 22.000€" control={<Radio required={true} />} label="20.000 - 22.000€" />
            <FormControlLabel value="22.000 - 24.000 €" control={<Radio required={true} />} label="22.000 - 24.000 €" />
            <FormControlLabel value="más de 24.000 €" control={<Radio required={true} />} label="más de 24.000 €" />
          </RadioGroup>
        </FormControl>
        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend">
            5. Indica la modalidad del puesto de trabajo:
          </FormLabel>
          <RadioGroup aria-label="workingMode" name="workingMode" value={offer.workingMode} onChange={handleChange('workingMode')}>
            {Object.keys(workingModes).map(mode => (
              <FormControlLabel
                key={mode}
                value={mode}
                control={<Radio required={true} />}
                label={workingModes[mode]}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {['ON_SITE', 'SEMI_PRESENTIAL'].includes(offer.workingMode) && <TextField required onChange={handleChange('opportunityLocation')} variant="outlined" margin="normal" fullWidth id="opportunityLocation" label="Ubicación de puesto de trabajo" helperText="Por favor indicad la localidad del puesto de trabajo" name="opportunityLocation" value={offer.opportunityLocation} />}
        {offer.workingMode == 'OTHER' && <TextField required onChange={handleChange('workingModeOther')} variant="outlined" margin="normal" fullWidth id="workingModeOther" label="Describe la modalidad y ubicación del puesto de trabajo" name="workingModeOther" value={offer.workingModeOther} />}
        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '1em' }}>
            6. ¿La empresa ofrece la posibilidad de un trabajo con jornada reducida?
          </FormLabel>
          <FormHelperText>
            (30 - 35 horas) y/o la posibilidad de acordar un horario intensivo
            (por ejemplo 7:00-15:00)
          </FormHelperText>
          <RadioGroup value={offer.reducedSchedule} onChange={handleChange('reducedSchedule')}>
            <FormControlLabel value="Sí" control={<Radio required={true} />} label="Sí" />
            <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
          </RadioGroup>
        </FormControl>
        <FormControl required component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '-1em' }}>
            7. ¿Es requisito indispensable que las candidatas posean un título universitario?
          </FormLabel>
          <RadioGroup value={offer.universitaryStudies} onChange={handleChange('universitaryStudies')}>
            <FormControlLabel value="Sí" control={<Radio required={true} />} label="Sí" />
            <FormControlLabel value="No" control={<Radio required={true} />} label="No" />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" required style={{ marginTop: '2em', marginBottom: '1em' }}>
          <FormLabel component="legend" style={{ marginBottom: '1em' }} htmlFor="incorporationDate">
            8. Fecha de incorporación aproximada
          </FormLabel>
          <DatePicker dateFormat="dd/MM/yyyy" selected={offer.incorporationDate} onChange={date => handleDate(date)} id="incorporationDate" required />
        </FormControl>
        <TextField required multiline onChange={handleChange('positionSummary')} variant="outlined" margin="normal" fullWidth id="positionSummary" label="10. Resumen del puesto de trabajo" helperText="Contar por favor de forma clara y resumida qué hará una adalaber si finalmente es contratada en la empresa: tipo de proyectos, tecnologías que utilizará, cómo será su onboarding, etc." name="positionSummary" value={offer.positionSummary} />
        <TextField required multiline onChange={handleChange('positionRequirements')} variant="outlined" margin="normal" fullWidth id="positionRequirements" label="11. Requisitos" helperText="Incluir requisitos obligarios del puesto y conocimientos valorables." name="positionRequirements" value={offer.positionRequirements} />
        <TextField required multiline onChange={handleChange('motivational')} variant="outlined" margin="normal" fullWidth id="motivational" label="12. ¿Por qué aplicar a esta oferta?" helperText="Incluir qué ofrece la empresa para un perfil junior en términos de formación, flexibilidad, ambiente, proyección, apoyo..." name="motivational" value={offer.motivational} />
        <TextField required onChange={handleChange('apoyoEnLaEmpresa')} variant="outlined" margin="normal" fullWidth multiline id="apoyoEnLaEmpresa" label="13. ¿Qué apoyo va a tener la adalaber en la empresa?" helperText="Como perfil junior que es, incluir aquí si la alumna contará con un tutor/a en la empresa, con formación específica durante su onboarding y/o si la empresa cuenta con al menos un perfil senior de front-end al que la alumna pueda consultar" name="apoyoEnLaEmpresa" value={offer.apoyoEnLaEmpresa} />
        <TextField required onChange={handleChange('contactEmail')} variant="outlined" margin="normal" fullWidth id="contactEmail" label="14. Mail de contacto" helperText="Indicar por favor el mail de contacto si tenemos alguna duda desde Adalab una vez leido el formulario" name="contactEmail" value={offer.contactEmail} type="email" />
        <TextField required multiline onChange={handleChange('comments')} variant="outlined" margin="normal" fullWidth id="comments" label="15. Comentarios" helperText="Incluir aquí cualquier comentario adicional que quieras que las alumnas interesadas en aplicar a la oferta puedan leer. Si el puesto es 100% en remoto o existe la posibilidad de incorporarse 100% en remoto pero luego se exigirá cierta presencialidad, por favor explicar en detalle aquí." name="comments" value={offer.comments} style={{ marginBotton: '1em' }} />
        <Box style={{ marginTop: '1em', marginBottom: '1em' }}>
          <FormControlLabel control={<Checkbox checked={offer.checkInterest} onChange={handleChange('checkInterest')} value={offer.checkInterest} color="primary" />} label="Entiendo que todas las alumnas de Adalab a cuyos CVs voy a tener acceso, tiene un perfil de Data Analyst junior y manejan Python, Inferencia estadística, Base de datos relacional, Herramientas de visualización, Data storytelling, Diseño de dashboards y estoy interesado/a en este perfil." />
        </Box>
        <Box mt={2}>
          <LoadingButton text="Crear oferta" loading={loading} disabled={!offer.checkInterest || loading} />
        </Box>
      </form>
    </Container>
}

export default NewOffer
