import stringify from 'csv-stringify/lib/sync';
import { saveAs } from 'file-saver';

const keepFields = (fieldsToKeep, fieldsToCount, list) =>
  list.map(item => {
    const reduced = {};
    fieldsToKeep.forEach(field => {
      if (item[field]) reduced[field] = item[field];
    });
    fieldsToCount.forEach(field => {
      if (item[field]) reduced[field] = item[field].length;
    });
    return reduced;
  });

export const download = (
  list,
  fileName,
  fieldsToKeep,
  fieldsToCount = []
) => () => {
  const options = {
    header: true,
  };
  const data = fieldsToKeep
    ? keepFields(fieldsToKeep, fieldsToCount, list)
    : list;

  const string = stringify(data, options);
  var file = new File([string], `${fileName}.csv`, {
    type: 'text/csv;charset=utf-8',
  });
  saveAs(file);
};

export const getSummariesForEmployer = (acceptedAdalabers, employer) => {
  const summaries = acceptedAdalabers.reduce((acc, adalaber) => {
    const summary =
      adalaber.messageForEmployer && adalaber.messageForEmployer[employer.id];
    if (summary) acc.push({ name: adalaber.fullName, summary });
    return acc;
  }, []);

  return download(summaries, `summaries-${employer.name}`);
};
