import React from 'react'
import { Container, Typography } from '@material-ui/core'
import coder from 'components/UI/Illustrations/undraw_code_thinking.svg'
import hire from 'components/UI/Illustrations/undraw_hiring.svg'
import * as ROUTES from 'constants/routes'

import { SignUpImg, SignUpBox } from 'components/UI/common'

export default function SignUp() {
  return (
    <Container
      disableGutters
      component="main"
      maxWidth="md"
      display="flex"
    >
      <SignUpBox to={ROUTES.SIGN_UP_ADALABERS} style={{borderRight: '1px solid var(--adalabBlueTrans2)'}}>
        <SignUpImg src={coder} alt="Soy Adalaber"/>
        <Typography component="h2" variant="h3" style={{ fontSize: '2.4rem' }}>Soy Adalaber</Typography>
      </SignUpBox>

      <SignUpBox to={ROUTES.SIGN_UP_EMPRESAS}>
        <SignUpImg src={hire} alt="Busco una Adalaber"/>
        <Typography component="h2" variant="h3" style={{ fontSize: '2.4rem' }}>Quiero contratar</Typography>
      </SignUpBox>
    </Container>
  )
}
