import { storage } from './firebase';

const storageRef = storage.ref();
const folderCV = 'CV/';
const folderIMG = 'IMG/'

export const uploadPDF = (name, content) => {
  const fileRef = storageRef.child(folderCV + name);
  return fileRef
    .put(content, { contentType: 'application/pdf' })
    .then(() => fileRef.getDownloadURL());
};

export const uploadIMG = (name, content) => {
  const fileRef = storageRef.child(folderIMG + name);
  return fileRef
    .put(content, { contentType: 'image/png' })
    .then(() => fileRef.getDownloadURL())
};