import moment from 'moment'
import firebase from 'fb/firebase'
const functions = firebase.functions()

const workingModes = {
  REMOTE: '100% remoto',
  ON_SITE: '100% presencial',
  SEMI_PRESENTIAL: 'Semipresencial',
  TEMPORARY_REMOTE: 'Remoto situación sanitaria',
  OTHER: 'Otro'
}

const insertionNotification = ({
  student,
  employer,
  employerOutOfNetwork,
  salary,
  contractType,
  contractDuration,
  incorporationDate,
  insertionComments,
  workingDayOther,
  workingMode,
  workingModeOther
}) => {
  const companyName = !employerOutOfNetwork ? employer : employerOutOfNetwork
  const weekHours = !workingDayOther
    ? 'Completa (40h)'
    : `Parcial (${workingDayOther})`
  const duration = !contractDuration ? 'No definido' : contractDuration
  const mode =
    workingMode !== 'OTHER' ? workingModes[workingMode] : workingModeOther

  fetch(
    'https://hooks.slack.com/services/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',
    {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `@channel La alumna _${student}_ ha sido insertada! :rocket:`
            }
          },
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*Nombre de la empresa:*\n${companyName}`
              },
              {
                type: 'mrkdwn',
                text: `*Tipo de contrato:*\n${contractType[0].toUpperCase() +
                  contractType.substring(1)}`
              },
              {
                type: 'mrkdwn',
                text: `*Duración del contrato:*\n${duration}`
              },
              {
                type: 'mrkdwn',
                text: `*Duración de la jornada:*\n${weekHours}`
              },
              {
                type: 'mrkdwn',
                text: `*Modalidad de trabajo:*\n${mode}`
              },
              {
                type: 'mrkdwn',
                text: `*Salario bruto anual:*\n${salary}`
              },
              {
                type: 'mrkdwn',
                text: `*Fecha de incorporación:*\n${moment(
                  incorporationDate
                ).format('L')}`
              },
              {
                type: 'mrkdwn',
                text: `*Comentarios:*\n${insertionComments}`
              }
            ]
          }
        ]
      })
    }
  )
}

const newOfferNotification = params => {
  const {
    acceptanceDeadline,
    companyName,
    contractType,
    salaryRange,
    workingMode,
    workingModeOther,
    employerId,
    jobLocation,
    bootcamp
  } = params

  let jobLocationInfo = workingModes[workingMode]

  if (['ON_SITE', 'SEMI_PRESENTIAL'].includes(workingMode)) {
    jobLocationInfo += ` en ${jobLocation}`
  }

  if (workingMode == 'OTHER') {
    jobLocationInfo = workingModeOther
  }

  const requestData = {
    mode: 'no-cors',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text:
              '@channel Tenemos nueva oferta en la plataforma de empleo :briefcase:'
          }
        },
        {
          type: 'section',
          fields: [
            {
              type: 'mrkdwn',
              text: `*Nombre de la empresa:*\n${companyName}`
            },
            {
              type: 'mrkdwn',
              text: `*Tipo de contrato:*\n${contractType}`
            },
            {
              type: 'mrkdwn',
              text: `*Salario bruto anual:*\n${salaryRange}`
            },
            {
              type: 'mrkdwn',
              text: `*Modalidad de trabajo:*\n${jobLocationInfo}.`
            }
          ]
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text:
              'Te aconsejamos, cuando apliques a la oferta en Emma, que si rellenas el campo de *_mensaje para la empresa_*, incluyas un mensaje personalizado y no un *_mensaje tipo_*.'
          }
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Tienes hasta el día ${moment(acceptanceDeadline).format(
              'DD/MM [a las] HH:mm'
            )} horas para inscribirte a esta oferta.`
          }
        },
        {
          type: 'actions',
          elements: [
            {
              type: 'button',
              text: {
                type: 'plain_text',
                text: 'Aplicar a la oferta',
                emoji: true
              },
              url: `https://emma.adalab.es/offers/${employerId}`
            }
          ]
        }
      ]
    })
  }

  const newOfferEmailNotification = functions.httpsCallable('emailNewOffer')

  if (bootcamp !== 'Data analyst') {
                                     // #promocion-paz-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-quiros-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-radia-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-salas-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-trotula-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-ursula-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-valentina-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-alice-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     // #promocion-woods-bolsadeempleo
                                     fetch('https://hooks.slack.com/services/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', requestData)

                                     newOfferEmailNotification(
                                       {
                                         companyName: companyName,
                                         contractType: contractType,
                                         workingMode: jobLocationInfo,
                                         salary: salaryRange,
                                         deadline: moment(
                                           acceptanceDeadline
                                         ).format(
                                           'DD/MM [a las] HH:mm'
                                         ),
                                         offerLink: `https://emma.adalab.es/offers/${employerId}`,
                                         bootcamp:
                                           'Programación web'
                                       }
                                     )
                                   } else {
    // #promocion-armada-da-bolsadeempleo
    fetch('https://hooks.slack.com/services/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', requestData)

    // #promocion-bouman-da-bolsadeempleo
    fetch('https://hooks.slack.com/services/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', requestData)

    // #promocion-carol-da-bolsadeempleo
    fetch('https://hooks.slack.com/services/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', requestData)

    // #promocion-dorothy-da-bolsadeempleo
    fetch('https://hooks.slack.com/services/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', requestData)

    // #promocion-evelyn-da-bolsadeempleo
    fetch('https://hooks.slack.com/services/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', requestData)

    // #promocion-farzana-da-bolsadeempleo
    fetch('https://hooks.slack.com/services/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', requestData)

    newOfferEmailNotification({
      companyName: companyName,
      contractType: contractType,
      workingMode: jobLocationInfo,
      salary: salaryRange,
      deadline: moment(acceptanceDeadline).format('DD/MM [a las] HH:mm'),
      offerLink: `https://emma.adalab.es/offers/${employerId}`,
      bootcamp: 'Data analyst'
    })
  }
}

export { insertionNotification, newOfferNotification }
