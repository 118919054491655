import React from 'react'
import { useSession } from 'fb/auth'
import { useOpportunities } from 'fb/db'
import { useFirestore } from 'reactfire'

import { TableOfOffers as EmployerList } from '../admin/employer/List'
import NothingToSeeHere from 'components/UI/Illustrations/NothingToSeeHere'
import * as STATUS from 'constants/status'

const Offers = ({ adalaber }) => {
  const user = useSession()
  const employers = useOpportunities()
  const db = useFirestore()
  const [hideOffersConfig, setHideOffersConfig] = React.useState({})

  React.useEffect(() => {
    const docRef = db.collection('config').doc('general')

    docRef.get().then(doc => {
      const { currentPromo, hideOffersToCurrentPromoToDate } = doc.data()

      setHideOffersConfig({
        currentPromo,
        hideOffersToCurrentPromoToDate: hideOffersToCurrentPromoToDate?.toDate()
      })
    })
  }, [])

  if (!user) return null

  const profile = adalaber ? adalaber : user.profile
  const employed = profile && (profile.employer || profile.employerOutOfNetwork)
  const noRGPD = profile && !profile.rgpdConsent

  console.log('PF2', profile.bootcamp)

  let employerList = user.isAdalaber
    ? employers.filter(e => {
        const offerBootcamp = e.bootcamp || 'Programación web'
        const candidateBootcamp = profile.bootcamp || 'Programación web'

        return offerBootcamp === candidateBootcamp
      })
    : employers
        .filter(e => e.status !== STATUS.NEW)
        .filter(e => {
          const offerBootcamp = e.bootcamp || 'Programación web'
          const candidateBootcamp = profile.bootcamp || 'Programación web'

          return offerBootcamp === candidateBootcamp
        })
        .filter(
          e =>
            (profile.accepted && profile.accepted.includes(e.id)) ||
            e.status === STATUS.VALIDATED
        )
        .sort((a, b) => {
          if (a.status === b.status) return b.requestDate - a.requestDate
          return STATUS.ALL.indexOf(a.status) - STATUS.ALL.indexOf(b.status)
        })

  if (employed)
    return (
      <NothingToSeeHere text={`¡Ya tienes trabajo, ${profile.fullName}!`} />
    )

  if (noRGPD)
    return (
      <NothingToSeeHere
        text={`Recuerda marcar la casilla de tratamiento de datos en tu perfil y recarga la página. ¡Necesitamos tu consentimiento antes de entrar en la bolsa de empleo, ${
          profile.fullName
        }!`}
      />
    )

  if (
    profile.promo == hideOffersConfig.currentPromo &&
    Date.now() < hideOffersConfig.hideOffersToCurrentPromoToDate
  ) {
    employerList = []
  }

  if (profile.promo != 'nerea') {
    employerList = employerList.filter(
      employer => employer.id != 'ZhwZ2ctDerOPoLiOVGix'
    )
  }

  return (
    <>
      {employerList && employerList.length > 0 ? (
        <>
          {/* <Typography variant="body2" gutterBottom>
            Para obtener más detalles sobre una oferta y confirmar el envío de
            tu CV, pincha en el nombre de la empresa.
          </Typography> */}
          <EmployerList adalaber={profile} employers={employerList} />
        </>
      ) : (
        <NothingToSeeHere text="Aquí aparecerán las empresas colaboradoras, para que revises su información y decidas si quieres aplicar y que vean tu perfil y CV online" />
      )}
    </>
  )
}

export default Offers
