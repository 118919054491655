import React from 'react';
import { SvgIcon } from '@material-ui/core'


const Library = () => (
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256.001 512C397 512 512 397 512 256.001S397 0 256.001 0C115 0 0 115.002 0 256.001S115 512 256.001 512z" fill="#ffca4f"/><path d="M153.12 135.519V418.12l62.191-54.629V80.89h-8.092l-4.307 3.287H162.03l-.013-3.342-6.725.055-62.194 54.626h60.019l.003.003z" fill="#5dddd3"/><path d="M101.667 135.519h44.392l56.853-51.342H158.52l-56.853 51.342z" fill="#fff"/><path d="M168.834 121.717l-7.365-.113-15.407 13.915H101.67l16.131-14.571-7.979-.118L93.1 135.519V418.12h60.019l15.712-13.8V121.717h.003z" fill="#ff757c"/><path d="M249.685 135.519V418.12l62.189-54.629V80.887h-8.092l-4.304 3.292h-40.879l-.013-3.342-6.728.05-62.194 54.629h60.022v.003z" fill="#ecf0f1"/><path d="M198.233 135.519h44.391l56.853-51.342h-44.391l-56.853 51.342z" fill="#fff"/><path d="M265.397 121.717l-7.365-.113-15.407 13.915h-44.391l16.131-14.571-7.979-.118-16.722 14.689V418.12h60.019l15.712-13.8V121.717h.002z" fill="#ff757c"/><path d="M380.942 70.85l-46.047 68.785 72.694 273.09 46.047-68.785-72.694-273.09-7.819 2.083-3.315 4.283-39.505 10.516-.871-3.224-6.487 1.781-46.049 68.785 57.997-15.439 46.049-68.785z" fill="#5dddd3"/><path d="M285.174 152.871l42.899-11.418 41.732-64.237-42.896 11.418-41.734 64.24v-.003z" fill="#fff"/><path d="M346.529 122.255l-7.148 1.789-11.308 17.409-42.896 11.418 11.843-18.227-7.74 1.936-12.381 18.497 72.694 273.09 58-15.439 11.633-17.375-72.694-273.095-.003-.003z" fill="#ff757c"/><g fill="#fff"><path d="M394.411 146.759l-3.651-13.894-25.732 42.073 3.649 13.894 25.734-42.075v.002zM380.512 138.869l-1.938-9.028-17.367 28.399 1.941 9.026 17.367-28.396h-.003z"/></g></svg>
  </SvgIcon>
)

export default Library