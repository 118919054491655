import React, { useState, useEffect } from 'react'
import { useSession, useNewAppUrl } from 'fb/auth'

import { useLocation, Link } from 'react-router-dom'
import * as routes from 'constants/routes'
import { Tabs, Tab } from '@material-ui/core'
// Dialog, DialogContent, DialogActions, Button, TextField
// import { useModal } from 'react-modal-hook'

const AdminNavTabs = () => {
  const user = useSession()
  const { pathname } = useLocation()
  const token = useNewAppUrl('/admin/config')

  const AdminTabs = [routes.EMPLOYERS, routes.ADALABERS]
  // const NormalTabs = [routes.PROFILE, routes.OFFERS]

  const getTabIndex = (route, tabs) =>
    tabs.includes(route)
      ? tabs.indexOf(route)
      : route.includes('adalabers') || route.includes('admin/ofertas')
      ? 2
      : route.includes('employers') || route.includes('ofertas')
      ? 1
      : 0

  const [tabIndex, setTabIndex] = useState(getTabIndex(pathname, AdminTabs))

  /* eslint-disable */
  useEffect(
    () => {
      if (!user) return setTabIndex(0)
      // const tabs = user.isAdmin ? AdminTabs : NormalTabs
      return setTabIndex(getTabIndex(pathname, AdminTabs))
    },
    [pathname]
  )
  /* eslint-enable */

  if (!user) return null

  if (user.isAdmin)
    return (
      <Tabs
        onChange={(event, tabIndex) => setTabIndex(tabIndex)}
        value={tabIndex}
        indicatorColor="secondary"
      >
        <Tab
          component={Link}
          to={`${routes.EMPLOYERS}?orderBy=requestDate&desc=false`}
          label="Ofertas"
        />
        <Tab component={Link} to={routes.ADALABERS} label="Adalabers" />
        <Tab
          component={Link}
          onClick={() => window.location.replace(token)}
          label="Configuración"
        />
      </Tabs>
    )

  return null
}

export default AdminNavTabs
