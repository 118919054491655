import React from 'react'
// import { motion, AnimatePresence } from 'framer-motion'
import Spinner from 'react-spinkit'
import { Box } from '@material-ui/core'
import SmilingGirl from './CustomIcons/SmilingGirl'

export default function AdaSpinner() {
  // const [show, setShow] = React.useState(false)
  // React.useEffect(() => {
  //   let timeout = setTimeout(() => setShow(true), 300)
  //   return () => {
  //     clearTimeout(timeout)
  //   }
  // }, [])

  return (
    // <AnimatePresence exitBeforeEnter>
    //   {show && (
    //     <motion.div
    //       key="modal"
    //       initial={false}
    //       animate={{ opacity: 1 }}
    //       exit={{ opacity: 0 }}>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            position="fixed"
            alignItems="center"
            justifyContent="center"
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: '#14d9c4',
              zIndex: 99999,
            }}
          >
            <SmilingGirl style={{
              fill: 'white',
              height: '3em',
              width: '3em',
              marginBottom: '1em'
            }} />
            <Spinner
              fadeIn="half"
              name="folding-cube"
              color="white"
              style={{ transform: 'scale(4) rotage(45deg)' }}
            />
          </Box>
    //     </motion.div>
    //   )}
    // </AnimatePresence>
  )
}