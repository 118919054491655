import React from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { getTheDate, useUserView } from '../../../hooks'
import { useSession } from 'fb/auth'
import Markdown from 'components/UI/Markdown'

const workingModes = {
  REMOTE: '100% remoto',
  ON_SITE: '100% presencial',
  SEMI_PRESENTIAL: 'Semipresencial',
  TEMPORARY_REMOTE: 'Remoto situación sanitaria',
  OTHER: 'Otro'
}

const SingleOffer = ({ opp, showOriginal }) => {
  const user = useSession()
  const [userView] = useUserView()
  const isAdaView = userView === 'ada'
  if (!opp) return null
  const values = [{ original: '14. Bootcamp', detail: 'A que candidatas va dirigida esta oferta', value: opp.bootcamp || 'Programación web' }, { original: '1. Indica por favor el nivel mínimo de inglés que las candidatas deben tener', detail: 'Nivel de inglés mínimo requerido', value: opp.englishLevel }, { original: '2. Indica el tipo de contrato', detail: 'Tipo de contrato', value: opp.contractType === 'Otro' ? opp.otherContractType : opp.contractType }, { original: '3. Indica la escala salarial que ofrece la empresa', detail: 'Escala salarial', value: opp.salaryRange }, { original: '4. Indica la modalidad del puesto de trabajo: ', detail: 'Modalidad del puesto de trabajo', value: opp.workingMode === 'OTHER' ? opp.workingModeOther : workingModes[opp.workingMode] }, { original: '5. Ubicación de puesto de trabajo', detail: 'Ubicación del puesto de trabajo', value: opp.opportunityLocation }, { original: '6. ¿La empresa ofrece la posibilidad de un trabajo con jornada reducida?', detail: '¿Existe posibilidad de jornada reducida?', value: opp.reducedSchedule }, { original: '7 ¿Es requisito indispensable que las candidatas posean un título universitario?', detail: '¿Es requisito indispensable que las candidatas posean un título universitario?', value: opp.universitaryStudies }, { original: '8. Fecha de incorporación aproximada', detail: 'Fecha de incorporación aproximada', value: getTheDate(opp.incorporationDate).toLocaleDateString('es-ES') }, { original: '9. Resumen del puesto de trabajo', detail: 'Resumen del puesto de trabajo', value: <Markdown>
          {opp.positionSummary}
        </Markdown> }, { original: '21. Requisitos', detail: 'Requisitos del puesto de trabajo', value: <Markdown>
          {opp.positionRequirements || ''}
        </Markdown> }, { original: '10. ¿Por qué aplicar a esta oferta?', detail: 'Por qué aplicar a esta oferta', value: <Markdown>
          {String(opp.motivational)}
        </Markdown> }, { original: '11. ¿Qué apoyo va a tener la adalaber en la empresa?', detail: 'Qué apoyo vas a tener en la empresa', value: <Markdown>
          {String(opp.apoyoEnLaEmpresa)}
        </Markdown> }, { original: '12. Mail de contacto', detail: 'Mail de contacto', value: opp.contactEmail, hide: user.isAdalaber }, { original: '13. Comentarios', detail: 'Comentarios', value: opp.comments }]

  return (
    <List>
      {values.map(val => {
        if ((val.hide && (user.isAdalaber || isAdaView)) || !val.value)
          return null
        return (
          <ListItem key={val.detail} divider={!showOriginal}>
            <ListItemText
              primary={showOriginal ? val.original : val.detail}
              secondary={val.value}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default SingleOffer
