import React, { useState } from 'react'
import { useFirestore, useFirestoreDocData, useFunctions } from 'reactfire'

import * as STATUS from 'constants/status'
// import * as ROUTES from 'constants/routes'

import { getTheDate } from '../../hooks'
import { useEmployer } from 'fb/db'
import { useSession } from 'fb/auth'

import SingleOffer from '../employer/partials/SingleOffer'

import { useSnackbar } from 'notistack'
import { Button, TextField, Tabs, Tab, Toolbar, Divider, ListItemIcon, Box, Typography, List, ListItem, ListItemText, Switch, FormControl, FormGroup, FormControlLabel, Paper } from '@material-ui/core'
import TabPanel from 'components/UI/TabPanel'
import { Explore, Language, Link, Edit, AllOut, People } from '@material-ui/icons'
import {AdaProgress, BackBtn, AdaSectionHeader} from 'components/UI/common'
import Markdown from 'components/UI/Markdown'

import moment from 'moment'
import 'moment/locale/es'

const getURLLink = website =>
  website && website.startsWith('https') ? website : `https://${website}`

const OfferDetailAdalaber = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const functions = useFunctions()

  const user = useSession()
  const firestore = useFirestore()
  const fieldValue = useFirestore.FieldValue

  const profileRef = user.isAdmin ? firestore.doc('adalabers/' + props.profile.id) : firestore.doc('adalabers/' + user.uid)
  const profile = useFirestoreDocData(profileRef)
  
  const employer = useEmployer(props.id)
  // const employerRef = firestore.doc(`${employer.collection}/${employer.employer}/opportunities/${employer.id}`)
  const employerRef = firestore.collection('employerUsers').doc(employer.employer).collection('opportunities').doc(employer.id)

  const [messageForEmployer, setMessageForEmployer] = useState(() => {
    if (profile && profile.messageForEmployer && profile.messageForEmployer[employer.id]) return profile.messageForEmployer[employer.id]
    else return ('')
  })

  const [canEditMessage, setCanEditMessage] = useState(() => {
    return (profile && !profile.messageForEmployer) || (profile && profile.messageForEmployer && !profile.messageForEmployer[employer.id])
  })


  if (!employer || !user || !profile || !employer.acceptanceDeadline) return null

  const adaAccepted = profile && profile.accepted && profile.accepted.includes(employer.id)
  const adaContacted = profile && profile.contacted && profile.contacted.includes(employer.id)
  const fueraDePlazo = Date.parse(getTheDate(employer.acceptanceDeadline)) - Date.parse(new Date()) < 0
  
  const acceptOpp = async () => {
    setLoading(true)
    try {
      await profileRef.update({
        accepted: adaAccepted ? fieldValue.arrayRemove(employer.id) : fieldValue.arrayUnion(employer.id) 
      })
      await employerRef.update({
        accepted: adaAccepted ? fieldValue.arrayRemove(profile.id) : fieldValue.arrayUnion(profile.id) 
      })

      if (!adaAccepted) {
        enqueueSnackbar(`Has aplicado correctamente!`, { variant: 'success' })
      }
    } catch(err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'error' })
    }
    setLoading(false)
  }

  const contactOpp = async () => {
    setLoading(true)
    try {
      await profileRef.update({
        contacted: adaContacted ? fieldValue.arrayRemove(employer.id) : fieldValue.arrayUnion(employer.id) 
      })
      await employerRef.update({
        contacted: adaContacted ? fieldValue.arrayRemove(profile.id) : fieldValue.arrayUnion(profile.id) 
      })

      if (!adaContacted && STATUS.CAN_BE_CONTACTED.includes(employer.status)) {
        const updateStatus = functions.httpsCallable('updateEmployerStatus')
        await updateStatus({
          oppId: employer.id,
          employerId: employer.employer,
          status: STATUS.IN_PROCESS
        })
      }

      enqueueSnackbar(`Éxito`, { variant: 'success' })
    } catch(err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'error' })
    }
    setLoading(false)
  }

  const sendMessage = async () => {
    setLoading(true)
    try {
      await profileRef.update({
        messageForEmployer: {
          ...profile.messageForEmployer,
          [employer.id] : messageForEmployer
        }
      })
      enqueueSnackbar(`Guardado mensaje`, { variant: 'success' })
      setCanEditMessage(false)
    } catch(err) {
      enqueueSnackbar(`Error: ${err.message}`, { variant: 'error' })
    }
    setLoading(false)
  }

  return (
    <>
      {loading && <AdaProgress className="adaProgress" />}
      <AdaSectionHeader forwardedAs="DialogTitle">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" pt={2}>
          {
            employer.logo ? (
              <img style={{ maxWidth: 120 }} src={employer.logo} alt={employer.name} />
            ) : (
              <Typography component="h2" variant="h3">
                {employer.name}
              </Typography>
            )
          }
        </Box>
        <Toolbar width="100%" color="white">
          <Box display="flex" justifyContent="center" width="100%">
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
            >
              <Tab label="Empresa" />
              <Tab label="Oportunidad" />
              <Tab label="¡Me interesa!" />
            </Tabs>
          </Box>
        </Toolbar>
      </AdaSectionHeader>

      <TabPanel value={tab} index={0}>
        <List>
          <ListItem>
            <ListItemText disableTypography primary={<Typography>Descripción</Typography>} secondary={
              <Markdown>{employer.description}</Markdown>
            } />
          </ListItem>
          <Divider />

          <ListItem disableGutters alignItems="flex-start">
            <ListItemIcon>
              <AllOut />
            </ListItemIcon>
            <ListItemText primary="Tipología de empresa" secondary={employer.type} />
          </ListItem>
          <Divider />

          <ListItem disableGutters alignItems="flex-start">
            <ListItemIcon>
              <Explore />
            </ListItemIcon>
            <ListItemText primary="Localización" secondary={employer.location} />
          </ListItem>
          <Divider />

          <ListItem disableGutters alignItems="flex-start">
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary="Número de empleados" secondary={employer.numberOfEmployees} />
          </ListItem>
          <Divider />

          <ListItem disableGutters alignItems="flex-start">
            <ListItemIcon>
              <Language />
            </ListItemIcon>
            <ListItemText primary="Página web" secondary={<a href={getURLLink(employer.website)} target="_blank" rel="noopener noreferrer">
              {employer.website}
            </a>} />
          </ListItem>
          <Divider />

          <ListItem disableGutters alignItems="flex-start">
            <ListItemIcon>
              <Link />
            </ListItemIcon>
            <ListItemText primary="LinkedIn" secondary={<a href={getURLLink(employer.linkedin)} target="_blank" rel="noopener noreferrer">
              {employer.linkedin}
            </a>} />
          </ListItem>
        </List>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <SingleOffer opp={employer} />
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <Box>
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <Box mb={2}>
                <Paper variant="outlined">
                  <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={adaAccepted}
                          onChange={acceptOpp}
                          disabled={employer.status !== STATUS.VALIDATED || fueraDePlazo} color="primary" />
                      }
                      label={<Typography variant="h3">Quiero aplicar a esta oferta</Typography>}
                    />
                    
                    {!STATUS.CAN_BE_CONTACTED.includes(employer.status) && (
                      <Box style={{ transform: 'scale(.8)' }}>
                        <Typography style={{ opacity: .8 }}>
                          <strong>Deadline: {moment(getTheDate(employer.acceptanceDeadline)).format('HH:mm')}</strong>
                        </Typography>
                        <Typography>{moment(getTheDate(employer.acceptanceDeadline)).locale('es').format('LL')}</Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Box>

              {adaAccepted && (
                <Paper variant="outlined">
                  <Box p={2} minHeight={60} style={{ position: 'relative' }}>
                    <Typography gutterBottom variant="body2">Mensaje para la empresa (opcional)</Typography>
                    {
                      canEditMessage || (
                        <BackBtn size="small" onClick={() => setCanEditMessage(true)} position="right"><Edit /></BackBtn>
                      )
                    }
                    <Box>
                      {
                        canEditMessage ? (
                          <Box display="flex" flexDirection="column">
                            <TextField
                              id="messageForEmployer"
                              multiline
                              rowsMax="6"
                              value={messageForEmployer}
                              onChange={e => setMessageForEmployer(e.target.value)}
                              margin="normal"
                              name="messageForEmployer"
                              disabled={employer.status !== STATUS.VALIDATED}
                              style={{ marginTop: '0', fontSize: '125%' }}
                            />
                            <Button onClick={sendMessage} variant="outlined" color="primary" disabled={employer.status !== STATUS.VALIDATED}>
                              Guardar mensaje
                            </Button>
                          </Box>
                        ) : (
                          <Box onClick={() => setCanEditMessage(true)} style={{ fontSize: '125%' }}>
                            <Markdown>{messageForEmployer}</Markdown>
                          </Box>
                        )
                      }
                    </Box>
                  </Box>
                </Paper>
              )}

              {
                adaAccepted && STATUS.CAN_BE_CONTACTED.includes(employer.status) && (
                  <Box my={2}>
                    <Paper variant="outlined">
                      <Box p={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={adaContacted} 
                              onChange={contactOpp}
                              disabled={!STATUS.CAN_BE_CONTACTED.includes(employer.status)} 
                              color="primary" />
                            }
                          label={<Typography variant="h3">La empresa me ha contactado</Typography>}
                        />
                      </Box>
                    </Paper>
                  </Box>
                )
              }
            </FormGroup>
          </FormControl>
        </Box>
      </TabPanel>
    </>
  )
}

export default OfferDetailAdalaber
