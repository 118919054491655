import React from 'react'
import { useModal } from 'react-modal-hook'
import NewOffer from '../NewOffer'
import Confetti from 'react-confetti/dist/react-confetti'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core'
import { Close, Add } from '@material-ui/icons'
import { SignUpImg, BottomEditor } from 'components/UI/common'
import JoyRide from 'components/UI/Illustrations/undraw_joyride.svg'

export default function NuevaOfertaCard({ cardStyle, card, fab }) {
  const [showModal, hideModal] = useModal(({ in: open, onExited }) => (
    <Dialog open={open} onExited={onExited} onClose={hideModal}>
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Nueva Oferta
          <IconButton aria-label="close" onClick={hideModal}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <NewOffer showSuccessModal={showSuccessModal} hideModal={hideModal} />
    </Dialog>
  ))

  const [showSuccessModal, hideSuccessModal] = useModal(
    ({ in: open, onExited }) => (
      <>
        <Dialog
          maxWidth="xs"
          open={open}
          onExited={onExited}
          onClose={hideSuccessModal}
        >
          <DialogTitle align="center">
            <Typography component="h2" variant="h3">
              ¡Has creado con éxito la oferta!
            </Typography>
          </DialogTitle>
          <DialogContent align="center">
            <SignUpImg src={JoyRide} />
            <Typography>
              Su oferta ha sido creada correctamente, ya es empresa colaboradora
              de Adalab, ¡Enhorabuena! Puede ir a ofertas para consultarla o
              editarla.
            </Typography>
            <br />
            <Typography>
              <b>Importante:</b> si la oferta ya ha sido validada por el equipo
              de Adalab no podrá editarla, por lo que si es su caso póngase en
              contacto con <a href="mailto:laura@adalab.es">laura@adalab.es</a>
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={hideSuccessModal} color="primary">
              OK!
            </Button>
          </DialogActions>
        </Dialog>
        <Confetti style={{ zIndex: '999' }} />
      </>
    )
  )

  if (fab) {
    return (
      <Tooltip title="Nueva oferta" aria-label="Nueva oferta">
        <BottomEditor
          position="right"
          color="primary"
          onClick={showModal}
          aria-label="Nueva oferta"
        >
          <Add />
        </BottomEditor>
      </Tooltip>
    )
  } else
    return (
      <Card style={cardStyle}>
        <CardHeader title="Nueva oferta" />
        <CardContent>
          <Typography gutterBottom>
            Ahora que ya eres una empresa de nuestra red y has creado tu perfil,
            solo queda el paso de incluir la información sobre tu oferta. Una
            vez la crees, podrás consultar en 48 horas las candidatas
            interesadas en tu oferta, conocer sus perfiles y ponerte en contacto
            directo con ellas.
          </Typography>
          <Typography gutterBottom style={{ fontSize: '0.8rem' }}>
            Recuerda que una vez validada la oferta por el equipo de Adalab, no
            podrás editarla, así que si quieres realizar un cambio posterior a
            su validación escribe a{' '}
            <a
              style={{ color: '#14d9c4', textDecoration: 'underline' }}
              href="mailto:laura@adalab.es"
            >
              laura@adalab.es
            </a>
            .
          </Typography>
          <br />
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={showModal}
          >
            Crear oferta
          </Button>
        </CardContent>
      </Card>
    )
}
