import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
// import { useSession } from '../../firebase/auth'
import { useAuth } from 'reactfire'

import { useSnackbar } from 'notistack'
import { FixedLinearProgress } from 'uno-material-ui'

import { Container, Box } from '@material-ui/core'
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Link,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import FormHeader from 'components/UI/FormHeader'

const IniciarSesion = () => {
  // const user = useSession()
  const auth = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  
  const [credentials, setCredentials] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  })

  const handleChange = prop => event => {
    setCredentials({ ...credentials, [prop]: event.target.value })
  }

  const handleSubmit = async e => {
    setLoading(true)
    e.preventDefault()
    try {
      await auth.signInWithEmailAndPassword(credentials.email, credentials.password)
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
    } finally {
      // setLoading(false)
      // Lo quito porque da un error de no-op
    }
  }

  const handleClickShowPassword = () => {
    setCredentials({
      ...credentials,
      showPassword: !credentials.showPassword,
    })
  }

  // if (user) return null

  return (
    <Container disableGutters component="main" maxWidth="xs" style={{ display: 'flex' }}>
      <FixedLinearProgress isLoading={loading} />

      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="stretch" height="100%">
        <FormHeader icon="lock" title="Iniciar Sesión" />
        <form onSubmit={handleSubmit}>
          <TextField onChange={handleChange('email')} variant="outlined" margin="normal" required fullWidth id="email" label="Email" name="email" type="email" autoComplete="email" autoFocus />
          <TextField onChange={handleChange('password')} variant="outlined" margin="normal" required fullWidth name="password" label="Contraseña" value={credentials.password} type={credentials.showPassword ? 'text' : 'password'} id="password" autoComplete="current-password" InputProps={{ endAdornment: <InputAdornment position="end">
                  <IconButton aria-label="Enseñar o esconder contraseña" onClick={handleClickShowPassword} onMouseDown={e => e.preventDefault()}>
                    {credentials.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment> }} />
          <Box mt={2}>
            <Button type="submit" fullWidth variant="contained" color="primary" size="large">
              Iniciar Sesión
            </Button>
          </Box>
        </form>

        <Box pt={4} pb={4} display="flex" component="footer" justifyContent="space-between">
          <Link component={RouterLink} to="/pw-forget" variant="body2" className="blue">
            Olvidaste tu contraseña?
          </Link>
          <Link component={RouterLink} to="/signup" variant="body2" className="blue" align="right">
            No tienes cuenta? Crea una aquí!
          </Link>
        </Box>
      </Box>
    </Container>
  )
}

export default IniciarSesion