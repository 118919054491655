import React from 'react'
import firebase from 'fb/firebase'
import { useSession } from 'fb/auth'
import PropTypes from 'prop-types'

import { useSnackbar } from 'notistack'
// import { FixedLinearProgress } from 'uno-material-ui';

import { Container, Box, Divider, Typography } from '@material-ui/core'
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'

// import withAuthorization from '../auth/withAuthorization';

const EmployerProfile = ({ close }) => {
  const user = useSession()

  const [, setLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [credentials, setCredentials] = React.useState({
    cif: '',
    name: '',
    email: '',
    location: '',
    website: '',
    linkedin: '',
    logo: '',
    description: '',
    type: '',
    numberOfEmployees: '',
    rgpdConsent: false
  })

  /* eslint-disable */
  React.useEffect(() => {
    if (!user || !user.profile) return
    setCredentials({
      ...credentials,
      ...user.profile
    })
  }, [])
  /* eslint-enable */

  const handleSubmit = e => {
    console.log('SUBMIT!')
    e.preventDefault()
    try {
      setLoading(true)
      firebase
        .firestore()
        .collection('employerUsers')
        .doc(user.uid)
        .set({ ...credentials })
    } catch (err) {
      enqueueSnackbar('Error: ' + err.message, { variant: 'error' })
    }

    setLoading(false)
    enqueueSnackbar('Guardado perfil', { variant: 'success' })
    if (close) close()
  }

  const handleChange = prop => event => {
    setCredentials({ ...credentials, [prop]: event.target.value })
  }

  const handleCapture = ({ target }) => {
    const fileReader = new FileReader()
    // const name = target.accept.includes('image') ? 'images' : 'videos';

    fileReader.readAsDataURL(target.files[0])
    fileReader.onload = e => {
      setCredentials({
        ...credentials,
        logo: e.target.result
      })
    }
  }

  return (
    <Container disableGutters>
      <form onSubmit={handleSubmit}>
        <Typography gutterBottom variant="h6">
          Modifica tu perfil si es necesario
        </Typography>
        <Typography gutterBottom variant="body2">
          Si ya has completado tu perfil, en la pestaña oferta puedes incluir
          los datos de la oferta para que las candidatas de Adalab puedan
          inscribirse
        </Typography>
        <br />
        <Divider />

        <Box my={2} py={2}>
          {credentials.logo.length > 2 && (
            <img style={{ maxWidth: 160 }} src={credentials.logo} alt="" />
          )}
          <br />
          <input
            accept="image/*"
            id="button-file"
            multiple={false}
            type="file"
            style={{ display: 'none' }}
            onChange={handleCapture}
          />
          <label htmlFor="button-file">
            <Button variant="outlined" color="primary" component="span">
              {credentials.logo ? 'Cambiar logo' : 'Subir logo'}
            </Button>
          </label>
        </Box>

        <TextField
          onChange={handleChange('name')}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre de la empresa"
          helperText="Si el nombre legal es diferente del comercial, por favor incluir ambos"
          name="name"
          autoComplete="name"
          value={credentials.name}
        />
        <TextField
          onChange={handleChange('cif')}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="cif"
          label="CIF de la empresa"
          name="cif"
          value={credentials.cif}
        />

        <br />
        <br />

        <Divider />

        <TextField
          onChange={handleChange('location')}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="location"
          label="Ubicación de la empresa"
          helperText="Por favor indicar localidad"
          name="location"
          value={credentials.location}
        />

        <TextField
          onChange={handleChange('website')}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="website"
          label="Página web"
          type="website"
          name="website"
          value={credentials.website}
        />

        <TextField
          onChange={handleChange('linkedin')}
          variant="outlined"
          margin="normal"
          fullWidth
          id="linkedin"
          label="Página de Linkedin de la empresa"
          name="linkedin"
          value={credentials.linkedin}
        />

        <br />
        <br />

        <Divider />

        <br />

        <TextField
          multiline
          onChange={handleChange('description')}
          variant="outlined"
          margin="normal"
          fullWidth
          id="description"
          label="Breve descripción de la empresa"
          helperText="Contar de forma resumida a qué se dedica la empresa"
          name="description"
          value={credentials.description}
        />

        <FormControl component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '1em' }}>
            Tipología de la empresa
          </FormLabel>
          <RadioGroup
            aria-label="tipologia"
            name="tipologia"
            value={credentials.type}
            onChange={handleChange('type')}
          >
            <FormControlLabel
              value="Empresa de servicios de desarrollo para terceros (boutiques de desarrollo, consultoras, estudios de diseño web)"
              control={<Radio />}
              label="Empresa de servicios de desarrollo para terceros (boutiques de desarrollo, consultoras, estudios de diseño web)"
            />
            <FormControlLabel
              value="Empresa de producto (empresas cuyo producto es digital: aplicación, plataforma, ecommerce)"
              control={<Radio />}
              label="Empresa de producto (empresas cuyo producto es digital: aplicación, plataforma, ecommerce)"
            />
            <FormControlLabel
              value="Agencia de comunicación y/o marketing y/o publicidad"
              control={<Radio />}
              label="Agencia de comunicación y/o marketing y/o publicidad"
            />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" style={{ marginTop: '2em' }}>
          <FormLabel component="legend" style={{ marginBottom: '1em' }}>
            Número de empleados
          </FormLabel>
          <RadioGroup
            aria-label="numberOfEmployees"
            name="numberOfEmployees"
            value={credentials.numberOfEmployees}
            onChange={handleChange('numberOfEmployees')}
          >
            <FormControlLabel value="2-10" control={<Radio />} label="2-10" />
            <FormControlLabel value="11-50" control={<Radio />} label="11-50" />
            <FormControlLabel
              value="51-200"
              control={<Radio />}
              label="51-200"
            />
            <FormControlLabel
              value="201-500"
              control={<Radio />}
              label="201-500"
            />
            <FormControlLabel
              value="501-1.000"
              control={<Radio />}
              label="501-1.000"
            />
            <FormControlLabel
              value="1.001-5.000"
              control={<Radio />}
              label="1.001-5.000"
            />
            <FormControlLabel
              value="5.001-10.000"
              control={<Radio />}
              label="5.001-10.000"
            />
            <FormControlLabel
              value="Más de 10.000"
              control={<Radio />}
              label="Más de 10.000"
            />
          </RadioGroup>
        </FormControl>

        <Box my={4}>
          <FormLabel component="legend" gutterBottom>
            Tratamiento de datos
          </FormLabel>
          <br />
          <Typography gutterBottom>
            Los datos personales y de la empresa que nos facilites serán
            tratados por ASOCIACIÓN ADALAB como responsable de tratamiento con
            la finalidad de encontrar el perfil de alumna más adecuado a las
            necesidades de la empresa. Para ejercer tus derechos y más
            información consulta nuestra{' '}
            <a
              target="_blank"
              href="https://adalab.es/politica-de-privacidad/"
              rel="noopener noreferrer"
            >
              política de privacidad
            </a>
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={credentials.rgpdConsent}
                value={credentials.rgpdConsent}
                onChange={handleChange('rgpdConsent')}
              />
            }
            label={
              'Acepto el tratamiento de mis datos con la finalidad indicadas y según la política de privacidad'
            }
          />
        </Box>

        <Box mt={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
          >
            Guardar
          </Button>
        </Box>
      </form>
    </Container>
  )
}

EmployerProfile.propTypes = {
  authUser: PropTypes.object
}

// const authCondition = authUser => !!authUser;

// export default withAuthorization(authCondition)(EmployerProfile);
export default EmployerProfile
